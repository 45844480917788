// src/pages/dashboard/categories/Categories.jsx
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useFetchObjects from "../../../api/useFetchObjects";
import useDelete from "../../../api/useDelete";
import useUpdate from "../../../api/useUpdate";
import { useAuth } from "../../../context/AuthContext";
import useAdd from "../../../api/useAdd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Categories = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({});
  const [imagePreview, setImagePreview] = useState([]); // Should always be an array
  const [formData, setFormData] = useState({
    name: "",
    dari_name: "",
    pashto_name: "",
    image: [], // Initialize image as an empty array instead of null
    parent: "", // For the parent category selection
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    dari_name: "",
    pashto_name: "",
    image: "",
    parent: "",
  });
  const [filter, setFilter] = useState("");

  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const lang = localStorage.getItem("i18nextLng");

  const { handleAdd, resetForm, loading: adding } = useAdd("categories", token);
  const { handleUpdate, loading: updating } = useUpdate(
    "categories",
    token,
    "/dashboard/categories"
  );
  const { handleDelete, ConfirmDialog } = useDelete("categories", token);

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["CategoriesList"],
    `categories/?filter=${encodeURIComponent(filter)}`,
    token
  );

  useEffect(() => {
    refetch();
  }, [filter, refetch]);

  useEffect(() => {
    if (resetForm) {
      setFormData({
        name: "",
        dari_name: "",
        pashto_name: "",
        image: [],
      });
      setFormErrors({
        name: "",
        dari_name: "",
        pashto_name: "",
        image: "",
      });
      navigate("/dashboard/categories");
    }
  }, [resetForm, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    // Validate required fields
    if (!formData.name) errors.name = t("categories.fieldRequired");
    if (!formData.dari_name) errors.dari_name = t("categories.fieldRequired");
    if (!formData.pashto_name)
      errors.pashto_name = t("categories.fieldRequired");

    if (Object.keys(errors).length === 0) {
      const categoryData = new FormData();
      categoryData.append("name", formData.name);
      categoryData.append("dari_name", formData.dari_name);
      categoryData.append("pashto_name", formData.pashto_name);

      // Append image files only if new files have been selected
      if (formData.image && formData.image.length > 0) {
        formData.image.forEach((file) => categoryData.append("image", file));
      }
      handleUpdate(currentCategory.id, categoryData);
      setOpen(false);
    } else {
      setFormErrors(errors);
    }
  };

  const handleAddCategory = (e) => {
    e.preventDefault();
    const errors = {};

    // Validate required fields
    if (!formData.name) errors.name = t("categories.fieldRequired");
    if (!formData.dari_name) errors.dari_name = t("categories.fieldRequired");
    if (!formData.pashto_name)
      errors.pashto_name = t("categories.fieldRequired");

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const categoryData = new FormData();
    categoryData.append("name", formData.name);
    categoryData.append("dari_name", formData.dari_name);
    categoryData.append("pashto_name", formData.pashto_name);

    // Append image files only if available
    if (formData.image && formData.image.length > 0) {
      formData.image.forEach((file) => categoryData.append("image", file));
    }
    handleAdd(categoryData);
    setAddOpen(false); // Close the dialog after adding
  };

  const handleEdit = (row) => {
    setOpen(true);
    setImagePreview(row.image ? [row.image] : []); // Ensure it's an array
    setCurrentCategory(row);
    setFormData({
      name: row.name || "",
      dari_name: row.dari_name || "",
      pashto_name: row.pashto_name || "",
      image: [], // Reset image to an empty array on edit
    });
    setFormErrors({
      name: "",
      dari_name: "",
      pashto_name: "",
      image: "",
    });
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files || []);
    if (files.length > 0) {
      const urls = files.map((file) => URL.createObjectURL(file));
      setImagePreview(urls);
      setFormData({ ...formData, image: files });
      setFormErrors({ ...formErrors, image: "" });
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  const columns = [
    {
      field: "id",
      headerName: t("categories.id"),
      flex: 1,
      valueGetter: (params) => params.row.id || "",
    },
    {
      field: "image",
      headerName: t("categories.categoryPhoto"),
      flex: 2,
      renderCell: (params) => (
        <img
          src={params.row.image || ""}
          alt={params.row.name || "Category"}
          style={{ width: "6rem", height: "auto", padding: "4px" }}
        />
      ),
    },
    {
      field: "name",
      headerName: t("categories.categoryName"),
      flex: 1,
      cellClassName: "name-column--cell",
      valueGetter: (params) => params.row.name || "",
    },
    {
      field: "dari_name",
      headerName: t("categories.dari_title"),
      flex: 1,
      cellClassName: "name-column--cell",
      valueGetter: (params) => params.row.dari_name || "",
    },
    {
      field: "pashto_name",
      headerName: t("categories.pashto_title"),
      flex: 1,
      cellClassName: "name-column--cell",
      valueGetter: (params) => params.row.pashto_name || "",
    },
    {
      field: "actions",
      headerName: t("categories.action"),
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEdit(params.row)}
            style={{ margin: "0em 1em" }}
          >
            {t("categories.edit")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDelete(params.row.id)}
            sx={{ margin: "0px 5px" }}
          >
            {t("categories.delete")}
          </Button>
        </Stack>
      ),
    },
  ];

  // Revoke object URLs to prevent memory leaks
  useEffect(() => {
    return () => {
      imagePreview.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [imagePreview]);

  return (
    <Box m="20px">
      <Header
        title={t("categories.categories")}
        subtitle={t("categories.listCategories")}
      />
      <Stack direction="row" spacing={2} mb={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setAddOpen(true)}
        >
          {t("categories.addCategory")}
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={() => navigate("/dashboard/sub-categories")}
          sx={{ margin: "0px 10px" }}
        >
          {t("categories.subCategories", "Sub Categories")}
        </Button>
      </Stack>

      <Box
        m="20px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .name-column--cell": { color: colors.greenAccent[300] },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : isError ? (
          <Alert severity="error">{t("categories.fieldLoading")}</Alert>
        ) : (
          <DataGrid
            rows={Array.isArray(data) ? data : []}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            rowHeight={80}
            getRowId={(row) => row.id}
            pagination
            pageSize={10}
            rowsPerPageOptions={[10, 25, 50]}
            sx={{ mt: 1 }}
          />
        )}
      </Box>

      {/* Edit Dialog */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              textAlign: "center",
              backgroundColor: "#3f51b5",
              color: "#fff",
            }}
          >
            <Typography variant="h5">{t("categories.editCategory")}</Typography>
          </DialogTitle>
          <DialogContent
            sx={{ backgroundColor: colors.primary[400], maxHeight: "65vh" }}
          >
            <Grid container spacing={3} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  size="small"
                  label={t("categories.categoryName")}
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label={t("categories.dari_title")}
                  name="dari_name"
                  value={formData.dari_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label={t("categories.pashto_title")}
                  name="pashto_name"
                  value={formData.pashto_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ width: "90%", margin: "auto", mt: 2 }}>
                  <input
                    id="image-input-edit"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="image-input-edit">
                    <Button
                      variant="contained"
                      sx={{ textTransform: "capitalize" }}
                      component="span"
                      color="secondary"
                    >
                      {t("categories.changeImages")}
                    </Button>
                  </label>
                  {imagePreview && imagePreview.length > 0 && (
                    <Box
                      sx={{
                        width: "12rem",
                        height: "10rem",
                        cursor: "pointer",
                        border: "1px solid grey",
                        borderRadius: "8px",
                        overflow: "hidden",
                        marginTop: 2,
                      }}
                    >
                      <img
                        src={imagePreview[0]}
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: colors.primary[400],
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => setOpen(false)}
              color="primary"
              variant="contained"
              sx={{ m: 1 }}
            >
              {t("categories.cancel")}
            </Button>
            <Button
              type="submit"
              disabled={updating}
              color="secondary"
              variant="contained"
            >
              {updating ? <CircularProgress size={24} /> : t("categories.save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Add Dialog */}
      <Dialog
        open={addOpen}
        onClose={() => setAddOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <form onSubmit={handleAddCategory}>
          <DialogTitle
            sx={{
              textAlign: "center",
              backgroundColor: "#3f51b5",
              color: "#fff",
            }}
          >
            <Typography variant="h5">{t("categories.addCategory")}</Typography>
          </DialogTitle>
          <DialogContent
            sx={{ backgroundColor: colors.primary[400], maxHeight: "65vh" }}
          >
            <Grid container spacing={3} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  size="small"
                  label={t("categories.categoryName")}
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label={t("categories.dari_title")}
                  name="dari_name"
                  value={formData.dari_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label={t("categories.pashto_title")}
                  name="pashto_name"
                  value={formData.pashto_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ width: "90%", margin: "auto", mt: 2 }}>
                  <input
                    id="image-input-add"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="image-input-add">
                    <Button
                      variant="contained"
                      sx={{ textTransform: "capitalize" }}
                      component="span"
                      color="secondary"
                    >
                      {t("categories.uploadImage")}
                    </Button>
                  </label>
                  {imagePreview && imagePreview.length > 0 && (
                    <Box
                      sx={{
                        width: "12rem",
                        height: "10rem",
                        cursor: "pointer",
                        border: "1px solid grey",
                        borderRadius: "8px",
                        overflow: "hidden",
                        marginTop: 2,
                      }}
                    >
                      <img
                        src={imagePreview[0]}
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: colors.primary[400],
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => setAddOpen(false)}
              color="primary"
              variant="contained"
              sx={{ m: 1 }}
            >
              {t("categories.cancel")}
            </Button>
            <Button
              type="submit"
              disabled={adding}
              color="secondary"
              variant="contained"
            >
              {adding ? <CircularProgress size={24} /> : t("categories.save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmDialog />
    </Box>
  );
};

export default Categories;

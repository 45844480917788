// RelatedProducts.jsx
import React, { useEffect, useState, useContext } from "react";
import Slider from "react-slick";
import {
  Box,
  Button,
  Paper,
  Typography,
  IconButton,
  Skeleton,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useFetchObjects from "../../../../api/useFetchObjects";
import Cards from "../Cards";

const RelatedProducts = ({ productId }) => {
  // State to keep track of image indexes for each product
  const [currentImageIndex, setCurrentImageIndex] = useState({});
  const direction = localStorage.getItem("direction");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: relatedProducts,
    isLoading,
    isError,
  } = useFetchObjects(["related-products"], `products/${productId}/related/`);

  // Improved skeleton loading UI using MUI Skeleton components
  if (isLoading) {
    // Render a slider with skeleton cards similar to product cards
    const skeletonCards = [1, 2, 3, 4]; // Number of skeleton slides can be adjusted
    return (
      <Box sx={{ width: "100%", p: 2 }}>
        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "25px", md: "35px" },
            fontWeight: "bold",
            margin: "20px 5px",
          }}
        >
          {t("manage_product.relatedProducts", "Related Products")}
        </Typography>

        {skeletonCards.map((skeleton) => (
          <Box key={skeleton} sx={{ px: 1, direction: direction }}>
            <Paper
              sx={{
                border: "1px solid lightgray",
                borderRadius: "8px",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                maxHeight: "400px",
                p: 1,
              }}
            >
              {/* Skeleton for Image */}
              <Skeleton variant="rectangular" height={200} />
              <Box p={1}>
                {/* Skeleton for Product Title */}
                <Skeleton variant="text" width="80%" height={30} />
                {/* Skeleton for Price */}
                <Skeleton
                  variant="text"
                  width="40%"
                  height={25}
                  sx={{ mt: 1 }}
                />
                {/* Skeleton for Button */}
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={40}
                  sx={{ mt: 2 }}
                />
              </Box>
            </Paper>
          </Box>
        ))}
      </Box>
    );
  }

  if (!relatedProducts || relatedProducts.length === 0) {
    return (
      <Grid container justifyContent={"center"} sx={{ p: 4 }}>
        <Typography variant="h6">
          {t("manage_product.noRelatedProduct", "No related products found")}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        textAlign={"center"}
        padding={"20px 10px"}
        borderBottom={"3px solid #dce9f5"}
        mb={5}
      >
        <Typography variant="h3">
          {t("manage_product.relatedProducts")}
        </Typography>
      </Grid>
      <Cards products={relatedProducts} />
    </Grid>
  );
};

export default RelatedProducts;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Alert, // Import Alert for messages
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import useAdd from "../../../api/useAdd"; // Import useAdd hook
import { useTranslation } from "react-i18next"; // Import useTranslation

function ResetPassword() {
  const { t } = useTranslation(); // Initialize translation
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [message, setMessage] = useState(null);
  const [messageSeverity, setMessageSeverity] = useState("success"); // 'success' or 'error'
  const [resetEmail, setResetEmail] = useState(""); // State for reset password email
  const [formErrors, setFormErrors] = useState({});

  const { handleAdd, resetForm, loading, responseData } = useAdd(
    "reset-password-verify-email", // API endpoint for password reset
    "", // Optional redirect URL
    t("user.sendResetLinkSuccess"), // Success message
    t("user.sendResetLinkFailure") // Failure message
  );

  useEffect(() => {
    if (resetForm) {
      setResetEmail("");
      setMessage(t("user.verificationEmailSent"));
      setMessageSeverity("success");
      setFormErrors({});
    }
  }, [resetForm, responseData, t]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};

    if (!resetEmail) {
      errors.email = t("user.emailRequired");
    }

    if (Object.keys(errors).length === 0) {
      handleAdd({ email: resetEmail }); // Submit reset password request
    } else {
      setFormErrors(errors);
    }
  };

  const handleChange = (e) => {
    setResetEmail(e.target.value);
    setFormErrors({ ...formErrors, email: "" });
  };

  const textFieldStyles = {
    input: {
      color: theme.palette.text.primary,
      bgcolor: "rgba(255, 255, 255, 0.9)",
      borderRadius: "10px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.grey[400],
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
    },
    label: {
      color: theme.palette.grey[600],
      "&.Mui-focused": {
        color: theme.palette.primary.main,
      },
    },
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "88vh",
        width: "100%",
        background: `linear-gradient(135deg, #e0f7fa 30%, #fce4ec 90%)`, // Light soft colors
      }}
    >
      <CssBaseline />
      <Grid
        item
        xs={11.5}
        sm={8}
        md={6}
        lg={5}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Card
          elevation={12}
          sx={{
            width: "100%",
            maxWidth: "500px", // Set max width for larger screens
            borderRadius: "10px", // Rounded corners
            padding: isSmallScreen ? 2 : 3, // Adjust padding for smaller screens
            boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.2)", // Subtle shadow
            background: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
            backdropFilter: "blur(20px)", // Glassmorphism effect
            transition: "transform 0.3s ease-in-out",
            "&:hover": {
              transform: "translateY(-10px)", // Subtle hover effect
            },
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                component="h1"
                variant={isSmallScreen ? "h6" : "h5"}
                color={theme.palette.primary.main}
                fontWeight="700"
                sx={{ mb: 2 }}
              >
                {t("user.resetPassword")}
              </Typography>
              <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                sx={{ mb: 3, fontStyle: "italic" }}
              >
                {t("user.pleaseEnterEmail")}
              </Typography>

              {message && (
                <Alert
                  severity={messageSeverity}
                  sx={{ width: "100%", mb: 2 }}
                  onClose={() => setMessage(null)}
                >
                  {message}
                </Alert>
              )}

              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  id="email"
                  label={t("user.emailLabel")}
                  name="email"
                  value={resetEmail}
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                  onChange={handleChange}
                  InputProps={{
                    sx: textFieldStyles.input,
                  }}
                  InputLabelProps={{
                    sx: textFieldStyles.label,
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 4,
                    mb: 2,
                    width: "100%",
                    padding: "12px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    ":hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)", // Light shadow
                    transition: "all 0.3s ease-in-out",
                    transform: loading ? "scale(0.98)" : "scale(1)", // slight shrink effect while loading
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    t("user.sendResetLink")
                  )}
                </Button>

                <Grid container justifyContent="center" sx={{ mt: 2 }}>
                  <Grid item>
                    <Link to="/login" style={{ textDecoration: "none" }}>
                      <Typography
                        variant="body2"
                        color={theme.palette.text.secondary}
                        sx={{
                          ":hover": { color: theme.palette.primary.main },
                          cursor: "pointer",
                        }}
                      >
                        {t("user.backToLogin")}
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ResetPassword;

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
  Avatar,
  CircularProgress,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Switch,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Alert,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import HomeIcon from "@mui/icons-material/Home";
import StoreIcon from "@mui/icons-material/Store";
import BadgeIcon from "@mui/icons-material/Badge";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";
import useUpdate from "../../../api/useUpdate";
import useFetchObject from "../../../api/useFetchObject";
import { useAuth } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";
import useDelete from "../../../api/useDelete";
import EmailVerify from "./EmailVerify";

function Profile() {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const userID = user?.user?.id;
  const theme = useTheme();
  const [openEmailVerify, setOpenEmailVerify] = useState("");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isImage, setIsImage] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false); // Add state for confirmation dialog
  const [pendingField, setPendingField] = useState(null); // Store the field pending for edit confirmation

  const {
    data: userData,
    isError,
    isLoading,
  } = useFetchObject(["profile"], "profile", userID, token);

  const [formData, setFormData] = useState(userData || {});
  const [formErrors, setFormErrors] = useState({});
  const [editField, setEditField] = useState(null);

  const [imagePreview, setImagePreview] = useState(userData?.image || null);
  const [workLicensePreview, setWorkLicensePreview] = useState(
    userData?.work_license_copy || null
  );
  const [tazkiraCopyPreview, setTazkiraCopyPreview] = useState(
    userData?.tazkira_copy || null
  );

  const [initialData, setInitialData] = useState(userData || {});
  const { handleUpdate, loading } = useUpdate("profile", token);
  const { handleDelete, ConfirmDialog } = useDelete("profile", token);

  // Use a single useEffect to set formData and initialData when userData changes
  useEffect(() => {
    if (userData) {
      setFormData(userData);
      setInitialData(userData);
    }
  }, [userData]);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;

    if (file) {
      if (name === "image") {
        setImagePreview(URL.createObjectURL(file));
        setFormData({ ...formData, image: file });
      }
      if (name === "work_license_copy") {
        setWorkLicensePreview(URL.createObjectURL(file));
        setFormData({ ...formData, work_license_copy: file });
      }
      if (name === "tazkira_copy") {
        setTazkiraCopyPreview(URL.createObjectURL(file));
        setFormData({ ...formData, tazkira_copy: file });
      }
    }
  };

  // Open confirmation dialog before editing
  const openEditDialog = (field) => {
    if (field === "image") {
      // Bypass confirmation for image
      setIsImage(true);
      setEditField("image");
    } else if (userData?.is_seller) {
      setPendingField(field); // Set the pending field to confirm
      setIsConfirmOpen(true); // Open confirmation dialog
    } else {
      setEditField(field);
    }
  };

  const handleSubmit = () => {
    let errors = {};

    if (!userData?.is_seller && formData?.is_seller) {
      if (!formData?.tazkira_number)
        errors.tazkira_number = t("user.tazkiraNumberRequired");
      if (!formData?.tazkira_copy)
        errors.tazkira_copy = t("user.tazkiraCopyRequired");

      if (!formData?.shop_name) errors.shop_name = t("user.addressRequired");

      if (!formData?.shop_address)
        errors.shop_address = t("user.shopAddressRequired");
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const submitData = new FormData();

    for (const key in formData) {
      if (formData[key] !== initialData[key]) {
        // Ensure booleans are properly added
        if (typeof formData[key] === "boolean") {
          submitData.append(key, formData[key] ? "true" : "false");
        } else {
          submitData.append(key, formData[key]);
        }
      }
    }

    if (formData.image && formData.image !== initialData.image) {
      submitData.append("image", formData.image);
    }
    if (
      formData.work_license_copy &&
      formData.work_license_copy !== initialData.work_license_copy
    ) {
      submitData.append("work_license_copy", formData.work_license_copy);
    }
    if (
      formData.tazkira_copy &&
      formData.tazkira_copy !== initialData.tazkira_copy
    ) {
      submitData.append("tazkira_copy", formData.tazkira_copy);
    }

    // verified should not change when user edit it's image
    if (!isImage || !formData.is_seller) {
      submitData.append("verified", false);
    }

    handleUpdate(userID, submitData); // Submit the updated formData
    setEditField(null); // Close the edit dialog
  };

  // Handle confirmation for editing
  const handleConfirmEdit = () => {
    setEditField(pendingField); // Set the actual field to edit after confirmation
    setIsConfirmOpen(false); // Close the confirmation dialog
  };

  const handleCancelConfirm = () => {
    setPendingField(null); // Reset the pending field
    setIsConfirmOpen(false); // Close the confirmation dialog
  };

  const handleOpenEmailVerify = () => {
    setOpenEmailVerify(!openEmailVerify);
  };

  if (isLoading) {
    return (
      <Grid container mt={3} justifyContent={"center"}>
        <CircularProgress />
      </Grid>
    );
  }

  if (isError) {
    return (
      <Grid container mt={3} justifyContent={"center"}>
        <Alert security="error">{t("user.failedLoadProfile")}</Alert>
      </Grid>
    );
  }
  const getFieldLabel = (field) => {
    switch (field) {
      case "first_name":
        return t("user.firstName");
      case "last_name":
        return t("user.lastName");
      case "username":
        return t("user.username");
      case "gender":
        return t("user.gender");
      case "email":
        return t("user.email");
      case "shop_name":
        return t("user.shop_name");
      case "is_seller":
        return t("user.seller");
      case "phone_number":
        return t("user.phoneNumber");
      case "tazkira_number":
        return t("user.tazkiraNumber");
      case "shop_address":
        return t("user.shopAddress");
      case "shop_name":
        return t("user.shopName1");
      case "is_visible_phone":
        return t("user.phoneNumber");
      case "work_license_copy":
        return t("user.workLicense");
      case "tazkira_copy":
        return t("user.tazkiraCopy");
      default:
        return "";
    }
  };

  const renderEditDialog = (field) => (
    <Dialog
      open={Boolean(editField)}
      onClose={() => setEditField(null)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle sx={{ textTransform: "capitalize" }}>
        {t("user.edit")} {t("user.d")} {getFieldLabel(field)} {t("user.edit1")}
      </DialogTitle>
      <DialogContent>
        {field !== "work_license_copy" &&
          field !== "tazkira_copy" &&
          field !== "is_seller" &&
          field !== "is_visible_phone" &&
          field !== "gender" &&
          field !== "image" && (
            <TextField
              name={field}
              value={formData[field] || ""}
              onChange={handleChange}
              fullWidth
              label={`${t("user.enter")} ${getFieldLabel(field)} ${t(
                "user.enterYour"
              )} `}
              margin="dense"
            />
          )}
        {field === "is_visible_phone" && (
          <Box>
            <FormControl component="fieldset" sx={{ mb: 2 }}>
              <FormLabel component="legend">
                {t("user.visiblePhoneNumber")}
              </FormLabel>
              <RadioGroup
                row
                name="is_visible_phone"
                value={formData?.is_visible_phone ? "true" : "false"} // Ensure boolean is mapped to a string for the radio buttons
                onChange={
                  (e) =>
                    setFormData({
                      ...formData,
                      is_visible_phone: e.target.value === "true",
                    }) // Convert back to boolean when updating the state
                }
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={t("user.yes")}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={t("user.no")}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        )}
        {field === "is_seller" && (
          <Box
            component={"form"}
            sx={{
              p: 3,
              borderRadius: 2,
              boxShadow: 1,
              backgroundColor: "#f9f9f9",
            }}
          >
            <FormControl component="fieldset" sx={{ mb: 2 }}>
              <FormLabel
                component="legend"
                sx={{ fontWeight: "bold", color: "#333" }}
              >
                {t("user.accountType")}
              </FormLabel>
              <RadioGroup
                row
                name="is_seller"
                value={formData?.is_seller ? "true" : "false"}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    is_seller: e.target.value === "true",
                  })
                }
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label={t("user.seller")}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label={t("user.buyer")}
                />
              </RadioGroup>
            </FormControl>

            {formData?.is_seller && (
              <>
                {!userData?.is_seller && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormLabel
                        htmlFor="address"
                        sx={{ mt: 1.5, fontWeight: "bold" }}
                      >
                        {t("user.shopName")}
                      </FormLabel>
                      <TextField
                        fullWidth
                        placeholder={t("user.shopName")}
                        name="shop_name"
                        variant="outlined"
                        sx={{ mb: 2 }}
                        value={formData?.shop_name}
                        onChange={handleChange}
                        error={!!formErrors.shop_name}
                        helperText={formErrors.shop_name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormLabel
                        htmlFor="shop_address"
                        sx={{ mt: 1.5, fontWeight: "bold" }}
                      >
                        {t("user.shopAddress")}
                      </FormLabel>
                      <TextField
                        fullWidth
                        placeholder={t("user.enterShopAddress")}
                        name="shop_address"
                        variant="outlined"
                        sx={{ mb: 2 }}
                        value={formData?.shop_address}
                        onChange={handleChange}
                        error={!!formErrors.shop_address}
                        helperText={formErrors.shop_address}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormLabel
                        htmlFor="tazkira_number"
                        sx={{ mt: 1.5, fontWeight: "bold" }}
                      >
                        {t("user.tazkiraNumber")}
                      </FormLabel>
                      <TextField
                        fullWidth
                        placeholder={t("user.enterTazkiraNumber")}
                        type="number"
                        name="tazkira_number"
                        variant="outlined"
                        sx={{ mb: 2 }}
                        value={formData?.tazkira_number}
                        onChange={handleChange}
                        error={!!formErrors.tazkira_number}
                        helperText={formErrors.tazkira_number}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormLabel
                        htmlFor="tazkira_copy"
                        sx={{ mt: 1.5, fontWeight: "bold" }}
                      >
                        {t("user.uploadTazkiraCopy")}
                      </FormLabel>
                      <TextField
                        fullWidth
                        name="tazkira_copy"
                        type="file"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        sx={{ mb: 2 }}
                        onChange={handleFileChange}
                        error={!!formErrors.tazkira_copy}
                        helperText={formErrors.tazkira_copy}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Box>
        )}
        {field === "gender" && (
          <Box>
            <FormControl component="fieldset" sx={{ mb: 2 }}>
              <FormLabel component="legend">{t("user.gender")}</FormLabel>
              <RadioGroup
                row
                name="gender"
                value={formData?.gender} // Ensure boolean is mapped to a string for the radio buttons
                onChange={
                  (e) =>
                    setFormData({
                      ...formData,
                      gender: e.target.value,
                    }) // Convert back to boolean when updating the state
                }
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={t("user.male")}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label={t("user.female")}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        )}
        {field === "work_license_copy" || field === "tazkira_copy" ? (
          <Box mt={2} sx={{ display: "flex", flexDirection: "column" }}>
            <FormLabel>
              {" "}
              {`${t("user.uploadYour")} ${getFieldLabel(field)}`}
            </FormLabel>
            <TextField
              accept=".pdf,image/*"
              type="file"
              name={field}
              onChange={handleFileChange}
              id={`upload-${field?.replace("_", " ")}`}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        ) : null}
        {field === "image" && (
          <Box
            mt={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box>
              <TextField
                label={t("user.uploadImage")}
                component="span"
                accept="image/*"
                type="file"
                name="image"
                onChange={handleFileChange}
                id={`upload-image`}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setEditField(null)} color="secondary">
          {t("user.cancel")}
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : t("user.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        justifyContent={"center"}
        sx={{
          pt: isSmallScreen ? 2 : 4,
          padding: "25px 20px",

          borderRadius: "10px",
          pb: 5,
          background:
            "linear-gradient(135deg,rgb(217, 245, 240) 50%,rgb(255, 255, 255) 50%)",
        }}
      >
        <Grid item xs={12} md={10}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ textAlign: "center", mb: 4 }}>
              <CssBaseline />
              <Avatar
                alt="Profile Picture"
                src={formData?.image || "/default-avatar.png"}
                sx={{ width: 140, height: 140, mx: "auto", boxShadow: 3 }}
              />

              <IconButton
                onClick={() => openEditDialog("image")}
                sx={{ ml: "auto" }}
              >
                <CameraAltIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box ml={2} margin={"0px 10px"}>
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    mt: 2,
                    ml: 1,
                    mr: 1,
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  {formData?.first_name || t("user.firstName")}{" "}
                  {formData?.last_name || t("user.lastName")}
                </Typography>
                <Button
                  endIcon={<DeleteIcon />}
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => handleDelete(userData?.id)} // Wrap in an anonymous function
                >
                  {t("user.removeAccount")}
                </Button>
              </Box>
              {userData?.is_seller && !userData?.verified && (
                <Box textAlign={"center"}>
                  <Typography margin={"0px 7px"}>
                    {t("user.verifyMsg")}
                  </Typography>

                  <Button
                    variant="contained"
                    size="small"
                    color="success"
                    sx={{ textTransform: "capitalize", mt: 1 }}
                    onClick={handleOpenEmailVerify}
                  >
                    {t("user.verify")}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ boxShadow: "0px 0px 6px 1px #555563" }}>
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                    {t("user.personalInformation")}
                  </Typography>
                  <Divider sx={{ mb: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Box display="flex" alignItems="center">
                        <PersonIcon sx={{ mr: 2, color: "primary.main" }} />
                        <Typography variant="body2">
                          <strong>{t("user.username")}:</strong>{" "}
                          {formData?.username || t("user.notProv")}
                        </Typography>
                        <IconButton
                          onClick={() => openEditDialog("username")}
                          sx={{ ml: "auto" }}
                          disabled
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box display="flex" alignItems="center">
                        <PersonIcon sx={{ mr: 2, color: "primary.main" }} />
                        <Typography variant="body2">
                          <strong> {t("user.gender")}:</strong>{" "}
                          {formData?.gender === 1
                            ? t("user.male")
                            : t("user.female") || t("user.notProv")}
                        </Typography>
                        <IconButton
                          onClick={() => openEditDialog("gender")}
                          sx={{ ml: "auto" }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box display="flex" alignItems="center">
                        <PersonIcon sx={{ mr: 2, color: "primary.main" }} />
                        <Typography variant="body2">
                          <strong>{t("user.firstName")}:</strong>{" "}
                          {formData?.first_name || t("user.notProv")}
                        </Typography>
                        <IconButton
                          onClick={() => openEditDialog("first_name")}
                          sx={{ ml: "auto" }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box display="flex" alignItems="center">
                        <PersonIcon sx={{ mr: 2, color: "primary.main" }} />
                        <Typography variant="body2">
                          <strong>{t("user.lastName")}:</strong>{" "}
                          {formData?.last_name || t("user.notProv")}
                        </Typography>
                        <IconButton
                          onClick={() => openEditDialog("last_name")}
                          sx={{ ml: "auto" }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box display="flex" alignItems="center">
                        <EmailIcon sx={{ mr: 2, color: "primary.main" }} />
                        <Typography variant="body2">
                          <strong>{t("user.email")}:</strong>{" "}
                          {formData?.email || t("user.notProv")}
                        </Typography>
                        <IconButton
                          onClick={() => openEditDialog("email")}
                          sx={{ ml: "auto" }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box display="flex" alignItems="center">
                        <PhoneIcon sx={{ mr: 2, color: "primary.main" }} />
                        <Typography variant="body2">
                          <strong>{t("user.phoneNumber")}:</strong>{" "}
                          {formData?.phone_number || t("user.notProv")}
                        </Typography>
                        <IconButton
                          onClick={() => openEditDialog("phone_number")}
                          sx={{ ml: "auto" }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Grid>

                    {/* <Grid item xs={12} sm={6}>
                      <Box display="flex" alignItems="center">
                        <HomeIcon sx={{ mr: 2, color: "primary.main" }} />
                        <Typography variant="body2">
                          <strong>{t("user.address")}:</strong>{" "}
                          {formData?.address || t("user.notProv")}
                        </Typography>
                        <IconButton
                          onClick={() => openEditDialog("address")}
                          sx={{ ml: "auto" }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <Box display="flex" alignItems="center">
                        <BadgeIcon sx={{ mr: 2, color: "primary.main" }} />
                        <Typography variant="body2">
                          <strong>{t("user.areYouSeller")}:</strong>{" "}
                          {formData?.is_seller ? t("user.yes") : t("user.no")}
                        </Typography>
                        <IconButton
                          onClick={() => openEditDialog("is_seller")}
                          sx={{ ml: "auto" }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {userData?.is_seller && (
              <Grid item xs={12}>
                <Card sx={{ boxShadow: 3 }}>
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                      {t("user.sellerInformation")}
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center">
                          <BadgeIcon sx={{ mr: 2, color: "primary.main" }} />
                          <Typography variant="body2">
                            <strong>{t("user.tazkiraNumber")}:</strong>{" "}
                            {formData?.tazkira_number || t("user.notProv")}
                          </Typography>
                          <IconButton
                            onClick={() => openEditDialog("tazkira_number")}
                            sx={{ ml: "auto" }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center">
                          <StoreIcon sx={{ mr: 2, color: "primary.main" }} />
                          <Typography variant="body2">
                            <strong>{t("user.shopAddress")}:</strong>{" "}
                            {formData?.shop_address || t("user.notProv")}
                          </Typography>
                          <IconButton
                            onClick={() => openEditDialog("shop_address")}
                            sx={{ ml: "auto" }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center">
                          <StoreIcon sx={{ mr: 2, color: "primary.main" }} />
                          <Typography variant="body2">
                            <strong>{t("user.shopName")}:</strong>{" "}
                            {formData?.shop_name || t("user.notProv")}
                          </Typography>
                          <IconButton
                            onClick={() => openEditDialog("shop_name")}
                            sx={{ ml: "auto" }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center">
                          <BadgeIcon sx={{ mr: 2, color: "primary.main" }} />
                          <Typography variant="body2">
                            <strong>{t("user.showPhoneNumber")}</strong>{" "}
                            {formData?.is_visible_phone
                              ? t("user.yes")
                              : t("user.no")}
                          </Typography>
                          <IconButton
                            onClick={() => openEditDialog("is_visible_phone")}
                            sx={{ ml: "auto" }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center">
                          <BadgeIcon sx={{ mr: 2, color: "primary.main" }} />
                          <Typography variant="body2">
                            <strong>{t("user.workLicense")}:</strong>{" "}
                            {formData?.work_license_copy ? (
                              <a
                                href={formData?.work_license_copy}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {t("user.view")}
                              </a>
                            ) : (
                              t("user.notProv")
                            )}
                          </Typography>
                          <IconButton
                            onClick={() => openEditDialog("work_license_copy")}
                            sx={{ ml: "auto" }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center">
                          <BadgeIcon sx={{ mr: 2, color: "primary.main" }} />
                          <Typography variant="body2">
                            <strong>{t("user.tazkiraCopy")}:</strong>{" "}
                            {formData?.tazkira_copy ? (
                              <a
                                href={formData?.tazkira_copy}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {t("user.view")}
                              </a>
                            ) : (
                              t("user.notProv")
                            )}
                          </Typography>
                          <IconButton
                            onClick={() => openEditDialog("tazkira_copy")}
                            sx={{ ml: "auto" }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
          {editField && renderEditDialog(editField)}
          {/* Confirmation dialog */}
          <Dialog
            open={isConfirmOpen}
            onClose={handleCancelConfirm}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
          >
            <DialogTitle id="confirm-dialog-title">
              {t("user.confirmEdit")}
            </DialogTitle>
            <DialogContent>
              <Typography>
                {t("user.sureEdit")}
                {t("user.field")} <strong>{getFieldLabel(pendingField)}</strong>{" "}
                {t("user.ra")}
                <br />
                <span style={{ color: "red" }}>
                  {t("user.note")}
                  <strong> {t("user.unverified")}</strong> {t("user.until")}
                </span>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelConfirm} color="secondary">
                {t("user.cancel")}
              </Button>
              <Button onClick={handleConfirmEdit} color="primary">
                {t("user.confirm")}
              </Button>
            </DialogActions>
          </Dialog>
          <ConfirmDialog message={t("user.confirmDelete")} />
          <Dialog
            maxWidth="xl"
            open={openEmailVerify}
            onClose={handleOpenEmailVerify}
          >
            <DialogContent sx={{ width: "100vw" }}>
              <EmailVerify />
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Profile;

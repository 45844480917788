import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Card,
  Typography,
  Avatar,
  CircularProgress,
  Chip,
  Divider,
  useTheme,
} from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import useFetchObjects from "../../../api/useFetchObjects";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import useUpdate from "../../../api/useUpdate";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";

const StyledCard = styled(Card)(({ theme, isread }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: theme.spacing(2),
  border: `1px solid ${
    isread === "true" ? theme.palette.grey[300] : theme.palette.primary.main
  }`,
  background:
    isread === "true"
      ? theme.palette.background.paper
      : theme.palette.action.hover,
  padding: theme.spacing(2),
  transition: "transform 0.2s, box-shadow 0.2s",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: theme.shadows[4],
  },
}));

const NotificationHeader = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  justifyContent: "center",
}));

const Notifications = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["notifications"],
    `notifications/?recipient=${user?.user?.id || ""}&page_size=100`
  );

  const {
    handleUpdate,
    loading: updateLoading,
    isSuccess,
  } = useUpdate("notifications", token);

  // Refetch notifications when a notification is marked as read
  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess, refetch]);

  // Set notifications when data is fetched
  useEffect(() => {
    if (data && data.results) {
      setNotifications(data.results);
    }
  }, [data]);

  // Handle marking a notification as read
  const handleIsRead = useCallback(
    (notificationID) => {
      if (notificationID) {
        handleUpdate(notificationID, {
          is_read: true,
        });
      }
    },
    [handleUpdate]
  );

  // Handle redirection based on notification type
  const handleRedirect = useCallback(
    (type, id) => {
      const splittedType = type?.split("_");

      console.log("splittedType: ", splittedType[0]);

      if (splittedType[0] === "post") {
        navigate("/news", {
          state: { id: id },
        });
      } else if (splittedType[0] === "order") {
        navigate("/buyer-orders", {
          state: { id: id },
        });
      } else if (splittedType[0] === "audit") {
        navigate("/dashboard/user-logs", {
          state: { id: id },
        });
      }
    },
    [navigate]
  );

  return (
    <Grid
      container
      sx={{
        padding: { xs: 2, sm: 3, md: 4 },
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          borderRadius: 3,
          padding: { xs: 2, sm: 4 },
        }}
      >
        <NotificationHeader container>
          <Grid item>
            <Avatar
              sx={{
                bgcolor: theme.palette.primary.main,
                marginRight: theme.spacing(1),
                width: 56,
                height: 56,
              }}
            >
              <NotificationsActiveIcon fontSize="large" />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography
              variant="h4"
              component="h1"
              sx={{
                fontWeight: "bold",
                color: theme.palette.text.primary,
                textAlign: "center",
              }}
            >
              {t("notifications_center")}
            </Typography>
          </Grid>
        </NotificationHeader>
        <Divider sx={{ marginBottom: 3 }} />
        {isLoading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "50vh" }}
          >
            <CircularProgress color="primary" />
          </Grid>
        ) : isError ? (
          <Grid container justifyContent="center">
            <Typography
              color="error"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <ErrorIcon sx={{ marginRight: 1 }} />
              {t("error")}
            </Typography>
          </Grid>
        ) : notifications.length === 0 ? (
          <Grid container justifyContent="center">
            <Typography
              variant="body2"
              sx={{
                marginTop: 2,
                color: theme.palette.text.secondary,
                padding: 2,
                display: "flex",
                alignItems: "center",
              }}
            >
              <CheckCircleIcon sx={{ marginRight: 1 }} />
              {t("noNotificationAvailable")}
            </Typography>
          </Grid>
        ) : (
          <Grid container spacing={3} justifyContent="center">
            {notifications.map((notification) => (
              <Grid item xs={12} sm={6} md={4} key={notification.id}>
                <StyledCard isread={notification.is_read.toString()}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    onClick={() => {
                      handleIsRead(notification?.id);
                      handleRedirect(
                        notification?.notification_type,
                        notification?.target_object_id
                      );
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <Grid item>
                      <Avatar
                        sx={{
                          bgcolor: notification.is_read
                            ? theme.palette.grey[500]
                            : theme.palette.primary.main,
                          color: "#ffffff",
                          width: 56,
                          height: 56,
                        }}
                      >
                        <NotificationsActiveIcon fontSize="large" />
                      </Avatar>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "600",
                          color: notification.is_read
                            ? theme.palette.text.secondary
                            : theme.palette.primary.dark,
                        }}
                      >
                        {t(notification.verb)}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ marginTop: 0.5 }}
                      >
                        {new Date(notification.timestamp).toLocaleString()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography
                    variant="body1"
                    color="text.primary"
                    sx={{ marginTop: 2 }}
                  >
                    {t(notification.message, notification.extra || {})}
                  </Typography>
                  <Chip
                    icon={notification.is_read ? <CheckCircleIcon /> : null}
                    label={notification.is_read ? t("read") : t("unread")}
                    color={notification.is_read ? "default" : "primary"}
                    size="small"
                    sx={{
                      marginTop: 2,
                      alignSelf: "flex-start",
                    }}
                  />
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Notifications;

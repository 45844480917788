import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  IconButton,
} from "@mui/material";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { Favorite, FavoriteBorderOutlined } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import Context from "../../../context/Context";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";
import useFetchObjects from "../../../api/useFetchObjects";
import Advertisement from "../../components/addvertisement/Addvertisement";

/**
 * Helper function to return a product's localized name.
 * It first checks for the current language; if that value is missing or empty,
 * it falls back to the other language names.
 */
const getLocalizedProductName = (product, lang) => {
  const { name, dari_name, pashto_name } = product;
  const isValid = (str) => str && str.trim() !== "";

  if (lang === "en") {
    if (isValid(name)) return name;
    if (isValid(dari_name)) return dari_name;
    if (isValid(pashto_name)) return pashto_name;
  } else if (lang === "dr") {
    if (isValid(dari_name)) return dari_name;
    if (isValid(name)) return name;
    if (isValid(pashto_name)) return pashto_name;
  } else if (lang === "ps") {
    if (isValid(pashto_name)) return pashto_name;
    if (isValid(name)) return name;
    if (isValid(dari_name)) return dari_name;
  }
  // Fallback to any non-empty value or a default string.
  return isValid(name)
    ? name
    : isValid(dari_name)
    ? dari_name
    : isValid(pashto_name)
    ? pashto_name
    : "Unknown Product";
};

const Cards = ({ products }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    addToCart,
    cartItems,
    wishlistItems,
    addToWishlist,
    removeWishlistItem,
    removeCartItem,
  } = useContext(Context);
  const [currentImageIndex, setCurrentImageIndex] = useState({});

  const handleCardClick = (product) => {
    navigate(`/products/${product.id}`, { state: product });
  };

  const lang = localStorage.getItem("i18nextLng") || "en";

  const handleToggleCart = (event, product) => {
    event.stopPropagation();
    if (isInCart(product.id)) {
      removeCartItem(product.id);
    } else {
      addToCart(product);
    }
  };

  const isInCart = (productId) => {
    return cartItems.some((item) => item.id === productId);
  };

  const handleToggleWishList = (event, product) => {
    event.stopPropagation();
    if (isInWishlist(product.id)) {
      removeWishlistItem(product.id);
    } else {
      addToWishlist(product);
    }
  };

  const isInWishlist = (productId) => {
    return wishlistItems.some((item) => item.id === productId);
  };

  const calculateDiscountPrice = (price, discount) => {
    return discount ? (price - (price * discount) / 100).toFixed(2) : price;
  };

  const handleNextImage = (productId) => {
    setCurrentImageIndex((prevState) => ({
      ...prevState,
      [productId]: (prevState[productId] || 0) + 1,
    }));
  };

  const handlePrevImage = (productId) => {
    setCurrentImageIndex((prevState) => ({
      ...prevState,
      [productId]: (prevState[productId] || 0) - 1,
    }));
  };

  const getImageIndex = (productId, images) => {
    const index = currentImageIndex[productId] || 0;
    return index >= images.length ? 0 : index < 0 ? images.length - 1 : index;
  };

  // Fetch Advertisements
  const {
    data: advertisementsData,
    isLoading: isLoadingAdvertisements,
    isError: isErrorAdvertisements,
  } = useFetchObjects(["Advertisements"], "advertisement/");

  const getAdvertisement = (adIndex) => {
    if (!advertisementsData || advertisementsData.length === 0) return null;
    return advertisementsData[adIndex % advertisementsData.length];
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      {products &&
        products.map((product, index) => {
          const isInCartList = isInCart(product.id);
          const productName = getLocalizedProductName(product, lang);
          const images = product.images || [];
          const currentImage =
            images?.length > 0
              ? images[getImageIndex(product.id, images)]?.image
              : "default-image-url.jpg";
          const discountedPrice = calculateDiscountPrice(
            product.price,
            product.discount
          );

          const showAd = (index + 1) % 12 === 0;
          const ad = showAd ? getAdvertisement(Math.floor(index / 12)) : null;

          return (
            <React.Fragment key={product.id}>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3.5}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Paper
                  onClick={() => handleCardClick(product)}
                  sx={{
                    cursor: "pointer",
                    border: "1px solid lightgray",
                    borderRadius: "8px",
                    overflow: "hidden",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    maxHeight: "400px", // Ensure consistent height for all cards
                    transition: "box-shadow 0.2s ease-in-out",
                    ":hover": {
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "50vh", // Adjusted to use viewport height for better scaling
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center", // Added for proper alignment
                      overflow: "hidden", // Prevents overflow issues
                      backgroundColor: "#f0f0f0", // Optional: A light background for better contrast
                      borderRadius: "8px", // Optional: Adds a rounded border
                    }}
                  >
                    {/* Image Slider */}
                    <Box
                      component="img"
                      src={currentImage}
                      alt={productName}
                      sx={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain", // Ensures the image fits within the container while maintaining aspect ratio
                        borderRadius: "8px", // Optional: Matches the container border radius
                      }}
                    />
                    {images?.length > 1 && (
                      <>
                        <IconButton
                          sx={{
                            position: "absolute",
                            left: "8px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.7)",
                            },
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePrevImage(product.id);
                          }}
                        >
                          <ArrowBackIosIcon sx={{ marginLeft: "3px" }} />
                        </IconButton>
                        <IconButton
                          sx={{
                            position: "absolute",
                            right: "8px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.7)",
                            },
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleNextImage(product.id);
                          }}
                        >
                          <ArrowForwardIosIcon />
                        </IconButton>
                      </>
                    )}
                  </Box>

                  <Box padding={"10px"} flexGrow={1}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        marginBottom: "5px",
                      }}
                    >
                      {productName}
                    </Typography>
                    <Box>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          color="green"
                        >
                          {product?.price} {product.currency === 1 ? "؋" : "$"}
                        </Typography>
                        {product?.whole_price > 0 && (
                          <>
                            <span
                              style={{
                                height: "3px",
                                borderRadius: "10px",
                                width: "20px",
                                margin: "0px 5px",
                                backgroundColor: "green",
                                fontSize: "12px",
                              }}
                            ></span>
                            <Typography
                              variant="h6"
                              fontWeight="bold"
                              color="green"
                            >
                              {product?.whole_price}{" "}
                              {product.currency === 1 ? "؋" : "$"}
                            </Typography>
                          </>
                        )}
                      </Box>

                      <div
                        style={{
                          fontSize: "14px",
                          color: "green",
                          height: "10px",
                        }}
                      >
                        {product?.whole_price > 0 &&
                          `${t("home.moreThan")} ${
                            product?.whole_price_count
                          } ${t("home.wholePrice")} `}
                      </div>
                    </Box>
                    <Box
                      mt={2}
                      display="flex"
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      <Button
                        size="small"
                        sx={{
                          textTransform: "capitalize",
                          mr: 1,
                          padding: "5px 15px",
                          backgroundColor: "#FFB100", // Active state background matches subscribe button
                          color: "#000000",
                          ":hover": { backgroundColor: "#FFC500" },
                        }}
                        onClick={(e) => handleToggleCart(e, product)}
                        disabled={product?.quantity === 0}
                      >
                        <ShoppingCartOutlinedIcon
                          fontSize="small"
                          sx={{ marginRight: "5px" }}
                        />
                        {isInCartList
                          ? t("product.removeCard")
                          : t("product.addToCard")}
                      </Button>
                      <IconButton
                        onClick={(e) => handleToggleWishList(e, product)}
                        sx={{
                          color: isInWishlist(product.id) ? "red" : "grey",
                        }}
                      >
                        {isInWishlist(product.id) ? (
                          <Favorite />
                        ) : (
                          <FavoriteBorderOutlined />
                        )}
                      </IconButton>
                    </Box>
                  </Box>
                  {/* Discount Badge */}
                  {product.discount > 0 && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        backgroundColor: "green",
                        color: "white",
                        borderRadius: "5px",
                        padding: "3px 8px",
                        fontSize: "0.8rem",
                        zIndex: 2,
                      }}
                    >
                      {product.discount}% {t("product.off")}
                    </Box>
                  )}
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    p={1}
                    sx={{
                      borderTop: "1px solid #ddd",
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <PinDropIcon sx={{ color: "blue", mr: 1 }} />
                    <Typography variant="body2" fontWeight="bold">
                      {product?.province_name ? (
                        t(
                          `manage_product.${product?.province_name?.toLowerCase()}`
                        )
                      ) : (
                        <>
                          {product.location === 1
                            ? t("product.asanBawar")
                            : t("product.sellerShop")}
                        </>
                      )}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              {/* Advertisement Component */}
              {ad && (
                <Grid item xs={12}>
                  <Advertisement ad={ad} />
                </Grid>
              )}
            </React.Fragment>
          );
        })}
    </Grid>
  );
};

export default Cards;

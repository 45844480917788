import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useFetchObjects from "../../../api/useFetchObjects";
import ErrorPage from "../../../components/errorPage/ErrorPage";
import { useLocation } from "react-router-dom";
import Context from "../../../context/Context";
import CardsSkeleton from "./loading/CardsSkeleton";
import NoData from "../../../components/noData/NoData";
import { useTranslation } from "react-i18next";
import SellerProfile from "../sellerProfile/SellerProfile";
import InfiniteScroll from "react-infinite-scroll-component";

// Lazy load the Cards component
const Cards = lazy(() => import("./Cards"));

/**
 * Helper function to get the localized name for a category.
 * Falls back to 'name' or "Unknown" if a language-specific name is missing.
 */
const getLocalizedCategoryName = (category, lang) => {
  if (
    lang === "ps" &&
    category.pashto_name &&
    category.pashto_name.trim() !== ""
  ) {
    return category.pashto_name;
  } else if (
    lang === "dr" &&
    category.dari_name &&
    category.dari_name.trim() !== ""
  ) {
    return category.dari_name;
  } else if (category.name && category.name.trim() !== "") {
    return category.name;
  } else {
    return "Unknown";
  }
};

/**
 * Helper function to get the localized name for a sub-category.
 * Falls back to 'title' or "Unknown" if a language-specific title is missing.
 */
const getLocalizedSubCategoryName = (subCategory, lang) => {
  if (
    lang === "ps" &&
    subCategory.pashto_title &&
    subCategory.pashto_title.trim() !== ""
  ) {
    return subCategory.pashto_title;
  } else if (
    lang === "dr" &&
    subCategory.dari_title &&
    subCategory.dari_title.trim() !== ""
  ) {
    return subCategory.dari_title;
  } else if (subCategory.title && subCategory.title.trim() !== "") {
    return subCategory.title;
  } else {
    return "Unknown";
  }
};

// ------------------------------
// Original FilterList Component (for large screens - sidebar)
// ------------------------------
const FilterList = ({
  t,
  filters,
  colors,
  productsSizes,
  categoriesData,
  subCatData,
  isLoadingCategories,
  isLoadignSubCat,
  isErrorCategories,
  isErrorSubCat,
  handleFilterChange,
  lang,
}) => {
  return (
    <Paper
      elevation={1}
      sx={{
        p: 3,
        borderRadius: 2,
        backgroundColor: "background.paper",
        width: { xs: "100%", md: "300px" },
        maxHeight: { xs: "70vh", md: "80vh" },
        overflowY: "auto",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          mb: 2,
          borderBottom: 2,
          pb: 1,
          borderColor: "divider",
        }}
      >
        {t("product.filter")}
      </Typography>

      {/* Filter by Color */}
      <Accordion disableGutters sx={{ boxShadow: "none", mb: 1 }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            {t("product.color")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pl: 2 }}>
          <FormGroup>
            {colors.map((colorLabel, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    sx={{ "&.Mui-checked": { color: "primary.main" } }}
                    value={colorLabel}
                    checked={filters.color.includes(colorLabel)}
                    onChange={(e) => handleFilterChange(e, "color")}
                  />
                }
                label={colorLabel}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      {/* Filter by Size */}
      <Accordion disableGutters sx={{ boxShadow: "none", mb: 1 }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            {t("product.size")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pl: 2 }}>
          <FormGroup>
            {productsSizes?.map((sizeItem) => (
              <FormControlLabel
                key={sizeItem.id}
                control={
                  <Checkbox
                    sx={{ "&.Mui-checked": { color: "primary.main" } }}
                    value={sizeItem.id}
                    checked={filters.size.includes(sizeItem.id)}
                    onChange={(e) => handleFilterChange(e, "size")}
                  />
                }
                label={sizeItem.size}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      {/* Filter by Main Category with Nested Sub-Categories */}
      <Accordion disableGutters sx={{ boxShadow: "none" }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            {t("product.category")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pl: 1 }}>
          {isLoadingCategories || isLoadignSubCat ? (
            <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : isErrorCategories || isErrorSubCat ? (
            <Typography color="error">
              {t("product.failedToLoadCategories")}
            </Typography>
          ) : (
            categoriesData.map((cat) => {
              // Filter sub-categories for the given main category
              const subCats = subCatData.filter(
                (sub) =>
                  sub.main_category_details &&
                  sub.main_category_details.id === cat.id
              );
              return (
                <Accordion
                  key={cat.id}
                  disableGutters
                  sx={{
                    boxShadow: "none",
                    mb: 1,
                    border: "1px solid",
                    borderColor: "divider",
                    borderRadius: 1,
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                      {getLocalizedCategoryName(cat, lang)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ pl: 3 }}>
                    {subCats.length > 0 ? (
                      <FormGroup>
                        {subCats.map((sub) => (
                          <FormControlLabel
                            key={sub.id}
                            control={
                              <Checkbox
                                sx={{
                                  "&.Mui-checked": { color: "primary.main" },
                                }}
                                value={sub.id}
                                checked={filters.subCategory.includes(sub.id)}
                                onChange={(e) =>
                                  handleFilterChange(e, "subCategory")
                                }
                              />
                            }
                            label={getLocalizedSubCategoryName(sub, lang)}
                          />
                        ))}
                      </FormGroup>
                    ) : (
                      <Typography variant="caption">
                        {t("product.noSubCategories")}
                      </Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })
          )}
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

// ------------------------------
// New TopFilterBar Component (for md and smaller screens)
// ------------------------------
const TopFilterBar = ({
  t,
  filters,
  colors,
  productsSizes,
  categoriesData,
  subCatData,
  isLoadingCategories,
  isLoadignSubCat,
  isErrorCategories,
  isErrorSubCat,
  handleFilterChange,
  lang,
}) => {
  return (
    <Box sx={{ mb: 2 }}>
      {/* Color Filter */}
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="subtitle1" fontWeight="bold">
            {t("product.color")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup row>
            {colors.map((colorLabel, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    sx={{ "&.Mui-checked": { color: "primary.main" } }}
                    value={colorLabel}
                    checked={filters.color.includes(colorLabel)}
                    onChange={(e) => handleFilterChange(e, "color")}
                  />
                }
                label={colorLabel}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      {/* Size Filter */}
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="subtitle1" fontWeight="bold">
            {t("product.size")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup row>
            {productsSizes?.map((sizeItem) => (
              <FormControlLabel
                key={sizeItem.id}
                control={
                  <Checkbox
                    sx={{ "&.Mui-checked": { color: "primary.main" } }}
                    value={sizeItem.id}
                    checked={filters.size.includes(sizeItem.id)}
                    onChange={(e) => handleFilterChange(e, "size")}
                  />
                }
                label={sizeItem.size}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      {/* Category Filter */}
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="subtitle1" fontWeight="bold">
            {t("product.category")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isLoadingCategories || isLoadignSubCat ? (
            <Box sx={{ display: "flex", justifyContent: "center", py: 1 }}>
              <CircularProgress size={24} />
            </Box>
          ) : isErrorCategories || isErrorSubCat ? (
            <Typography color="error">
              {t("product.failedToLoadCategories")}
            </Typography>
          ) : (
            // For a top filter bar, we list all sub-categories inline.
            <FormGroup row>
              {categoriesData.map((cat) => {
                // Filter sub-categories for the given main category
                const subCats = subCatData.filter(
                  (sub) =>
                    sub.main_category_details &&
                    sub.main_category_details.id === cat.id
                );
                return subCats.length > 0 ? (
                  subCats.map((sub) => (
                    <FormControlLabel
                      key={sub.id}
                      control={
                        <Checkbox
                          sx={{ "&.Mui-checked": { color: "primary.main" } }}
                          value={sub.id}
                          checked={filters.subCategory.includes(sub.id)}
                          onChange={(e) => handleFilterChange(e, "subCategory")}
                        />
                      }
                      label={getLocalizedSubCategoryName(sub, lang)}
                    />
                  ))
                ) : (
                  <Typography key={cat.id} variant="caption" sx={{ mr: 1 }}>
                    {getLocalizedCategoryName(cat, lang)}
                  </Typography>
                );
              })}
            </FormGroup>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

// ------------------------------
// Products Component with Responsive Filter Layout
// ------------------------------
const Products = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { state } = location || {};
  const userInfo = state?.userInfo || null;
  const userID = userInfo?.id || "";
  const catID = state?.category || "";

  // Read language from localStorage.
  // Possible values: "en" (English), "ps" (Pashto), "dr" (Dari)
  const lang = localStorage.getItem("i18nextLng") || "en";

  // Pagination and product data states
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(6);
  const [allProducts, setAllProducts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [noDataMessage, setNoDataMessage] = useState(null);

  // Filters state: color, size, and subCategory
  const [filters, setFilters] = useState({
    color: [],
    size: [],
    subCategory: [],
  });

  // Get search term from context
  const { searchTerm } = useContext(Context);

  // Prepare API query parameters from filters
  const color = filters.color.join(",");
  const size = filters.size.join(",");
  const subCategory = filters.subCategory.join(",");

  // Fetch products using your custom hook
  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["ProductsList", page, filters, searchTerm, userID, subCategory],
    `products/?color=${color}&size=${size}&category=${subCategory}&search=${encodeURIComponent(
      searchTerm
    )}&user=${
      userID || ""
    }&user_verified=true&page=${page}&page_size=${rowsPerPage}`
  );

  // Fetch main categories
  const {
    data: categoriesData,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
  } = useFetchObjects(["CategoriesList"], "categories/");

  // Fetch sub-categories
  const {
    data: subCatData,
    isLoading: isLoadignSubCat,
    isError: isErrorSubCat,
  } = useFetchObjects(["sub-category"], "sub-category/");

  // Fetch product sizes
  const { data: productsSizes } = useFetchObjects(
    ["products-size"],
    "product-size/"
  );

  // Reset product list on filter or search changes
  useEffect(() => {
    setAllProducts([]);
    setPage(1);
    setHasMore(true);
    setNoDataMessage(null);
  }, [filters, searchTerm, userID]);

  // Debounce refetch to avoid excessive API calls
  useEffect(() => {
    const timer = setTimeout(() => {
      refetch();
    }, 500);
    return () => clearTimeout(timer);
  }, [filters, searchTerm, page, refetch]);

  // Append new products when data changes
  useEffect(() => {
    if (data && data.results) {
      setAllProducts((prevProducts) => {
        const newProducts = data.results.filter(
          (product) => !prevProducts.some((p) => p.id === product.id)
        );
        const updatedProducts = [...prevProducts, ...newProducts];
        if (updatedProducts.length >= data.count) {
          setHasMore(false);
          setNoDataMessage("There are no more products.");
        }
        return updatedProducts;
      });
    } else if (data && data.results.length === 0 && page === 1) {
      setNoDataMessage("No products found.");
      setHasMore(false);
    }
  }, [data, page]);

  // Handle filter change for color, size, and subCategory
  const handleFilterChange = useCallback((event, filterType) => {
    const { value } = event.target;
    setFilters((prevFilters) => {
      let updatedFilter = [...prevFilters[filterType]];
      // For numeric filters (size, subCategory), convert the value
      if (["size", "subCategory"].includes(filterType)) {
        const numericValue = Number(value);
        if (updatedFilter.includes(numericValue)) {
          updatedFilter = updatedFilter.filter((item) => item !== numericValue);
        } else {
          updatedFilter.push(numericValue);
        }
      } else {
        // For color, which is a string
        if (updatedFilter.includes(value)) {
          updatedFilter = updatedFilter.filter((item) => item !== value);
        } else {
          updatedFilter.push(value);
        }
      }
      return { ...prevFilters, [filterType]: updatedFilter };
    });
  }, []);

  // Example colors (translated)
  const colorOptions = [
    "red",
    "blue",
    "green",
    "yellow",
    "black",
    "white",
    "orange",
    "purple",
    "pink",
    "brown",
    "gray",
    "violet",
    "cyan",
    "magenta",
    "turquoise",
    "lavender",
    "maroon",
    "navy",
    "olive",
    "teal",
    "gold",
    "silver",
    "beige",
    "coral",
    "mint",
    "peach",
    "other",
  ].map((color) => t(`product.${color}`));

  // Responsive logic: For large screens (lg and up) we keep the sidebar;
  // for md and below, we show a top filter bar.
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Container maxWidth="xl" sx={{ py: { xs: 2, md: 4 } }}>
      <Grid container spacing={3} justifyContent="center">
        {/* Seller Profile */}
        {userInfo && (
          <Grid item xs={12}>
            <SellerProfile userInfo={userInfo} />
          </Grid>
        )}

        {/* Filter Section */}
        {isLgUp ? (
          // Large screens: display original sidebar filter.
          <Grid item xs={12} md={3} lg={2}>
            <FilterList
              t={t}
              filters={filters}
              colors={colorOptions}
              productsSizes={productsSizes}
              categoriesData={categoriesData || []}
              subCatData={subCatData || []}
              isLoadingCategories={isLoadingCategories}
              isLoadignSubCat={isLoadignSubCat}
              isErrorCategories={isErrorCategories}
              isErrorSubCat={isErrorSubCat}
              handleFilterChange={handleFilterChange}
              lang={lang}
            />
          </Grid>
        ) : (
          // Medium and smaller screens: display the top filter bar above the cards.
          <Grid item xs={12}>
            <TopFilterBar
              t={t}
              filters={filters}
              colors={colorOptions}
              productsSizes={productsSizes}
              categoriesData={categoriesData || []}
              subCatData={subCatData || []}
              isLoadingCategories={isLoadingCategories}
              isLoadignSubCat={isLoadignSubCat}
              isErrorCategories={isErrorCategories}
              isErrorSubCat={isErrorSubCat}
              handleFilterChange={handleFilterChange}
              lang={lang}
            />
          </Grid>
        )}

        {/* Products List */}
        <Grid item xs={12} md={isLgUp ? 9 : 12} lg={isLgUp ? 10 : 12}>
          <Suspense fallback={<CardsSkeleton />}>
            {isError ? (
              <ErrorPage />
            ) : (
              <InfiniteScroll
                dataLength={allProducts.length}
                next={() => setPage((prevPage) => prevPage + 1)}
                hasMore={hasMore}
                loader={
                  <Box
                    sx={{ display: "flex", justifyContent: "center", py: 2 }}
                  >
                    <CircularProgress />
                  </Box>
                }
                endMessage={
                  noDataMessage && (
                    <Typography
                      variant="body2"
                      sx={{ py: 2, textAlign: "center" }}
                    >
                      {noDataMessage}
                    </Typography>
                  )
                }
              >
                {isLoading && allProducts.length === 0 ? (
                  <CardsSkeleton />
                ) : allProducts.length === 0 ? (
                  <NoData message={noDataMessage} />
                ) : (
                  <Cards products={allProducts} />
                )}
              </InfiniteScroll>
            )}
          </Suspense>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Products;

// src/pages/dashboard/categories/Edit.jsx
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { useTranslation } from "react-i18next";
import useUpdate from "../../../../api/useUpdate";
import useFetchObjects from "../../../../api/useFetchObjects";
import { Autocomplete } from "@mui/material";

const Edit = ({ open, onClose, token, editData, onSuccess }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const { handleUpdate, loading, isSuccess } = useUpdate("sub-category", token);

  const [formData, setFormData] = useState({
    main_category: null,
    title: "",
    dari_title: "",
    pashto_title: "",

    description: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const {
    data: categories,
    isLoading,
    isError,
  } = useFetchObjects(["categories"], "categories/", token);

  useEffect(() => {
    if (editData) {
      setFormData({
        main_category: editData.main_category || null,
        title: editData.title || "",
        dari_title: editData?.dari_title || "",
        pashto_title: editData?.pashto_title || "",
        description: editData.description || "",
      });
    }
  }, [editData]);

  useEffect(() => {
    if (isSuccess) {
      setFormErrors({});
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    setFormErrors((prev) => ({ ...prev, [field]: "" }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = {};

    const payload = {
      main_category: formData?.main_category,
      title: formData?.title,
      dari_title: formData?.dari_title,
      pashto_title: formData?.pashto_title,
      description: formData?.description,
    };

    handleUpdate(editData.id, payload);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle
          style={{
            textAlign: "center",
            backgroundColor: "#3f51b5",
            color: "#fff",
          }}
        >
          <Typography variant="h5">
            {t("categories.editSubCategory")}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{ backgroundColor: colors.primary[400], maxHeight: "65vh" }}
        >
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              {isLoading ? (
                <CircularProgress />
              ) : isError ? (
                <Typography color="error">
                  {t("categories.failedToLoadCategories")}
                </Typography>
              ) : (
                <Autocomplete
                  options={categories || []}
                  getOptionLabel={(option) => option.name}
                  value={
                    categories.find(
                      (cat) => cat.id === formData.main_category
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    handleChange("main_category", newValue?.id || null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("categories.mainCategory")}
                      variant="outlined"
                      error={!!formErrors.main_category}
                      helperText={formErrors.main_category}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                size="small"
                label={t("categories.title")}
                name="title"
                value={formData.title}
                onChange={(e) => handleChange("title", e.target.value)}
                error={!!formErrors.title}
                helperText={formErrors.title}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                autoFocus
                size="small"
                label={t("categories.dari_title")}
                name="dari_title"
                value={formData.dari_title}
                onChange={(e) => handleChange("dari_title", e.target.value)}
                error={!!formErrors.dari_title}
                helperText={formErrors.dari_title}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                autoFocus
                size="small"
                label={t("categories.pashto_title")}
                name="pashto_title"
                value={formData.pashto_title}
                onChange={(e) => handleChange("pashto_title", e.target.value)}
                error={!!formErrors.pashto_title}
                helperText={formErrors.pashto_title}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                size="small"
                label={t("categories.description")}
                name="description"
                value={formData.description}
                onChange={(e) => handleChange("description", e.target.value)}
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: colors.primary[400],
            justifyContent: "center",
          }}
        >
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            sx={{ m: 1 }}
          >
            {t("categories.cancel")}
          </Button>
          <Button
            type="submit"
            disabled={loading}
            color="secondary"
            variant="contained"
          >
            {loading ? <CircularProgress size={24} /> : t("categories.save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Edit;

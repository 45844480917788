import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Paper,
  useTheme,
  useMediaQuery,
  Collapse,
} from "@mui/material";
import {
  Close as CloseIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
const ImageMedia = ({ src, alt }) => (
  <Box
    component="img"
    src={src}
    alt={alt}
    sx={{
      width: "100%",
      height: "100%",
      objectFit: "cover",
      transition: "transform 0.3s ease-in-out",
      "&:hover": {
        transform: "scale(1.05)",
      },
    }}
  />
);

const VideoMedia = ({ src }) => (
  <Box
    component="video"
    src={src}
    autoPlay
    loop
    muted
    playsInline
    sx={{
      width: "100%",
      height: "100%",
      objectFit: "cover",
    }}
  />
);

const Advertisement = ({ ad }) => {
  const [isVisible, setIsVisible] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getMediaType = () => {
    if (ad?.video) return "video";
    if (ad?.image) return "image";
    return "placeholder";
  };

  const mediaType = getMediaType();

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <Collapse sx={{ padding: 1 }} in={isVisible}>
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          overflow: "hidden",
          position: "relative",
          bgcolor: "background.paper",
          borderRadius: 2,
          transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            position: "relative",
          }}
        >
          {/* Media Section */}
          <Box
            sx={{
              width: { xs: "100%", sm: "40%" },
              height: { xs: "200px", sm: "260px" },
              position: "relative",
              overflow: "hidden",
            }}
          >
            {mediaType === "video" && <VideoMedia src={ad?.video} />}
            {mediaType === "image" && (
              <ImageMedia
                src={ad?.image || "/placeholder.svg"}
                alt="ad media"
              />
            )}
            {mediaType === "placeholder" && (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: "grey.200",
                  color: "text.secondary",
                }}
              >
                <Typography variant="body1">No media available</Typography>
              </Box>
            )}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  "linear-gradient(90deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%)",
                pointerEvents: "none",
              }}
            />
          </Box>

          {/* Content Section */}
          <Box
            sx={{
              flex: 1,
              p: { xs: 2, sm: 4 },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Typography
              variant="h4"
              component="h2"
              sx={{
                mb: 2,
                fontWeight: "bold",
                color: "text.primary",
                fontSize: { xs: "1.5rem", sm: "2rem" },
              }}
            >
              {ad?.title}
            </Typography>

            <Typography
              variant="body1"
              sx={{
                mb: 3,
                color: "text.secondary",
                fontSize: { xs: "0.875rem", sm: "1rem" },
                lineHeight: 1.6,
              }}
            >
              {ad?.description}
            </Typography>

            {ad?.external_link && (
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                component={Link}
                to={ad?.external_link || "#"}
                target="_blank" // Open in new tab
                rel="noopener noreferrer" // Security measure
                sx={{
                  alignSelf: "flex-start",
                  textTransform: "none",
                  px: 3,
                  py: 1,
                  borderRadius: 2,
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.dark",
                    transform: "translateX(4px)",
                  },
                  transition: "all 0.3s ease-in-out",
                }}
              >
                Learn More
              </Button>
            )}
          </Box>

          {/* Close Button */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              bgcolor: "background.paper",
              boxShadow: 2,
              "&:hover": {
                bgcolor: "grey.200",
              },
              zIndex: 1,
            }}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Paper>
    </Collapse>
  );
};

export default Advertisement;

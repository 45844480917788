import React, { useEffect, useState, useTransition } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAdd from "../../../api/useAdd"; // Import useAdd hook
import { useTranslation } from "react-i18next";

function ConfirmResetPassword() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Extract uid and token from the query parameters in the URL
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const uid = query.get("uid");

  const [formData, setFormData] = useState({
    password1: "",
    password2: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const { handleAdd, resetForm, loading, responseData } = useAdd(
    "reset-password-confirm", // API endpoint for password reset confirmation
    token,
    t("user.resetPasswordSuccess"), // Success message
    t("user.failedResetPassword") // Failure message
  );

  useEffect(() => {
    if (resetForm) {
      navigate("/");
      setFormData({
        password1: "",
        password2: "",
      });
      setFormErrors({});
    }
  }, [resetForm, responseData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};

    if (!formData.password1) {
      errors.password1 = t("user.newPasswordRequired");
    }

    if (formData.password1 !== formData.password2) {
      errors.password2 = t("user.matchPassword");
    }

    if (Object.keys(errors).length === 0) {
      handleAdd(formData); // Submit reset password form
    } else {
      setFormErrors(errors);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  const textFieldStyles = {
    input: {
      color: theme.palette.text.primary,
      bgcolor: "rgba(255, 255, 255, 0.9)",
      borderRadius: "10px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.grey[400],
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
    },
    label: {
      color: theme.palette.grey[600],
      "&.Mui-focused": {
        color: theme.palette.primary.main,
      },
    },
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "88vh",
        background: `linear-gradient(135deg, #e0f7fa 30%, #fce4ec 90%)`, // Light soft colors
      }}
    >
      <CssBaseline />
      <Grid
        item
        xs={11.5}
        sm={8}
        md={6}
        lg={5}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
        margin={"40px 0px "}
      >
        <Card
          elevation={12}
          sx={{
            width: "100%",
            maxWidth: "500px", // Set max width for larger screens
            borderRadius: "10px", // Rounded corners
            padding: isSmallScreen ? 2 : 3, // Adjust padding for smaller screens
            boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.2)", // Subtle shadow
            background: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
            backdropFilter: "blur(20px)", // Glassmorphism effect
            transition: "transform 0.3s ease-in-out",
            "&:hover": {
              transform: "translateY(-10px)", // Subtle hover effect
            },
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                component="h1"
                variant={isSmallScreen ? "h6" : "h5"}
                color={theme.palette.primary.main}
                fontWeight="700"
                sx={{ mb: 2 }}
              >
                {t("user.confirmResetPassword")}
              </Typography>
              <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                sx={{ mb: 3, fontStyle: "italic" }}
              >
                {t("user.enterNewPassword")}
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  name="password1"
                  label={t("user.newPassword")}
                  type="password"
                  value={formData.password1}
                  error={!!formErrors.password1}
                  helperText={formErrors.password1}
                  onChange={handleChange}
                  InputProps={{
                    sx: textFieldStyles.input,
                  }}
                  InputLabelProps={{
                    sx: textFieldStyles.label,
                  }}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  name="password2"
                  label={t("user.confirmPassword")}
                  type="password"
                  value={formData.password2}
                  error={!!formErrors.password2}
                  helperText={formErrors.password2}
                  onChange={handleChange}
                  InputProps={{
                    sx: textFieldStyles.input,
                  }}
                  InputLabelProps={{
                    sx: textFieldStyles.label,
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 4,
                    mb: 4,
                    width: "100%",
                    padding: "12px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    ":hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)", // Light shadow
                    transition: "all 0.3s ease-in-out",
                    transform: loading ? "scale(0.98)" : "scale(1)", // slight shrink effect while loading
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    t("user.resetPassword")
                  )}
                </Button>

                <Grid container justifyContent="center" sx={{ mt: 2 }}>
                  <Grid item>
                    <Link to="/login">
                      <Typography
                        variant="body2"
                        color={theme.palette.text.secondary}
                        sx={{
                          ":hover": { color: theme.palette.primary.main },
                        }}
                      >
                        {t("user.backToLogin")}
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ConfirmResetPassword;

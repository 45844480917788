import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  InputBase,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Add,
  ArrowBack,
  ArrowForward,
  SearchOutlined,
} from "@mui/icons-material";
import useFetchObjects from "../../../../api/useFetchObjects";
import useAdd from "../../../../api/useAdd";
import ErrorPage from "../../../../components/errorPage/ErrorPage";
import { useAuth } from "../../../../context/AuthContext";
import useUpdate from "../../../../api/useUpdate";
import useDelete from "../../../../api/useDelete";
import { useTranslation } from "react-i18next";
import EditProductDialog from "../../products/EditProductDialog";

const Products = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [currentProduct, setCurrentProduct] = useState({});
  const [imagePreview, setImagePreview] = useState([]);
  const auth = useAuth();
  const user = auth?.user;
  const token = auth?.user?.token;
  const navigate = useNavigate();
  const [product, setProduct] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = React.useState(4);

  const [searchType, setSearchType] = useState("search");
  const [category, setCategory] = useState("");

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["ProductsList"],
    `products/?${searchType}=${search}&user=${user?.user?.id}&page=${
      page + 1
    }&page_size=${pageSize}&category=${category}`,
    token
  );

  const [formData, setFormData] = useState({
    user: product?.user || "",
    name: product?.name || "",
    dari_name: product?.dari_name || "",
    pashto_name: product?.pashto_name || "",
    category: product?.category || "",
    price: product?.price || "",
    whole_price: product?.whole_price || "",
    whole_price_count: product?.whole_price_count || "",
    currency: product?.currency || "",
    quantity: product?.quantity || "",
    description: product?.description || "",
    dari_description: product?.dari_description || "",
    pashto_description: product?.pashto_description || "",
    discount: product?.discount || "",
    color: product?.colors || [],
    size: product?.size || "",
    uploaded_images: product?.uploaded_images || [],
    location: product?.location || "",
    is_visible_seller_info: product?.is_visible_seller_info || "",
  });

  const [formErrors, setFormErrors] = useState({
    user: "",
    name: "",
    category: "",
    price: "",
    currency: "",
    quantity: "",
    description: "",
    discount: "",
    color: [],
    size: "",
    uploaded_images: [],

    location: "",
    is_visible_seller_info: "",
  });

  const { handleUpdate, Buttons, loading } = useUpdate(
    `products`,
    token,
    "/dashboard/seller/products"
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};

    // Validate required fields
    // if (!formData.name) errors.name = t("manage_product.nameRequired");
    if (!formData.category)
      errors.category = t("manage_product.categoryRequired");
    if (!formData.currency)
      errors.currency = t("manage_product.currencyRequired");
    // if (!formData.description)
    // errors.description = t("manage_product.descriptionRequired");
    if (!formData.price) errors.price = t("manage_product.priceRequired");
    if (!formData.quantity)
      errors.quantity = t("manage_product.quantityRequired");
    if (!formData.location)
      errors.location = t("manage_product.locationRequired");

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const productData = new FormData();

    // Append all form fields to FormData
    Object.keys(formData).forEach((key) => {
      if (key === "uploaded_images" && formData[key].length > 0) {
        formData[key].forEach((file) => {
          productData.append("uploaded_images", file);
        });
      } else if (key === "color" && formData[key].length > 0) {
        // Append only the color name instead of the entire color object
        formData[key].forEach((color) => {
          productData.append("color", color.name); // Send just the name
        });
      } else {
        productData.append(key, formData[key]);
      }
    });

    handleUpdate(product?.id, productData);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  const columns = [
    { field: "id", headerName: t("manage_product.id"), flex: 0.5 },
    {
      field: "images",
      headerName: t("manage_product.photo"),
      flex: 1.2,
      renderCell: (params) => {
        return (
          <img
            src={params?.value[0]?.image}
            style={{ width: "5rem", height: "auto", padding: "4px" }}
          />
        );
      },
    },
    {
      field: "name",
      headerName: t("manage_product.productName"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "category",
      headerName: t("manage_product.category"),
      headerAlign: "left",
      renderCell: (params) => <div>{params?.row?.display_category}</div>,
      align: "left",
    },
    {
      field: "price",
      headerName: t("manage_product.price"),
      renderCell: (params) => (
        <div>
          {params?.row?.currency === 1
            ? `${params?.value} ؋`
            : `${params?.value} $`}
        </div>
      ),
      flex: 1,
    },

    {
      field: "quantity",
      headerName: t("manage_product.quantity"),
      flex: 1,
    },
    {
      field: "discount",
      headerName: t("manage_product.discount"),
      renderCell: (params) => (
        <div>
          <span>{params.value}</span>
          <span>%</span>
        </div>
      ),
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("manage_product.actions"),
      flex: 2,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setProduct(params.row);
              setOpenEdit(true);
            }}
          >
            {loading ? <CircularProgress /> : t("manage_product.edit")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDelete(params.row.id)}
            style={{ margin: "0em 1em" }}
          >
            {t("manage_product.delete")}
          </Button>
        </Stack>
      ),
    },
  ];

  const {
    data: subcategories,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
    refetch: refetchCategories,
  } = useFetchObjects(["SubCategoriesList"], `sub-category/`, token);

  useEffect(() => {
    refetch();
  }, [category, refetch, search, page, pageSize]);

  const { handleDelete, ConfirmDialog } = useDelete(`products`, token);
  const { handleDelete: deleteImage, ConfirmDialog: ConfirmDialogImage } =
    useDelete(`images`, token);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleEdit = (row) => {
    // setOpen(true);
    setCurrentProduct(row);

    // Map existing product images to objects with url and id
    const existingImages = row.images
      ? row.images.map((img) => ({ url: img.image, id: img.id }))
      : [];

    setFormData({
      name: row?.name || "",
      dari_name: row?.dari_name || "",
      pashto_name: row?.pashto_name || "",
      category: row?.category || "",
      price: row?.price || "",
      whole_price: row?.whole_price || "",
      whole_price_count: row?.whole_price_count || "",
      currency: row?.currency || "",
      quantity: row?.quantity || "",
      description: row?.description || "",
      dari_description: row?.dari_description || "",
      pashto_description: row?.pashto_description || "",
      discount: row?.discount || "",
      color: row?.colors || "",
      size: row?.size || "",
      location: row?.location || "",
      is_visible_seller_info: row?.is_visible_seller_info || "",
      user: row?.user || "",
    });
    setImagePreview(existingImages); // Show existing images in preview
  };

  const handleImageChange = (event) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      const newImageUrls = files.map((file) => ({
        url: URL.createObjectURL(file),
        id: null, // Newly uploaded images won't have an ID yet.
      }));

      // Combine the new images with the existing image previews
      setImagePreview((prevPreview) => [
        ...(prevPreview || []),
        ...newImageUrls,
      ]);

      // Update form data with the actual files
      setFormData((prevData) => ({
        ...prevData,
        uploaded_images: [...(prevData.uploaded_images || []), ...files],
      }));

      // Clear any error related to image uploads
      setFormErrors((prevErrors) => ({ ...prevErrors, uploaded_images: "" }));
    }
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < imagePreview.length - 3 ? prevIndex + 1 : 0
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : imagePreview.length - 3
    );
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  // useEffect(() => {
  //   if (loading) {
  //     setOpen(false);
  //   }
  // }, [loading]);

  const handleDeleteImage = (index, imageId) => {
    if (imageId) {
      // Call the API or use the `deleteImage` function to remove the image from the database.
      deleteImage(imageId);
    }

    // Remove the image from the local preview.
    setImagePreview((prevPreview) =>
      (prevPreview || []).filter((_, i) => i !== index)
    );

    // Update the form data to remove the corresponding file from the uploaded images.
    setFormData((prevData) => {
      const updatedUploadedImages = (prevData.uploaded_images || []).filter(
        (_, i) => i !== index
      );
      return { ...prevData, uploaded_images: updatedUploadedImages };
    });
  };

  if (isError || isErrorCategories) {
    return <ErrorPage />;
  }
  const options = [
    t("product.red"),
    t("product.blue"),
    t("product.green"),
    t("product.yellow"),
    t("product.black"),
    t("product.white"),
    t("product.orange"),
    t("product.purple"),
    t("product.pink"),
    t("product.brown"),
    t("product.gray"),
    t("product.violet"),
    t("product.cyan"),
    t("product.magenta"),
    t("product.turquoise"),
    t("product.lavender"),
    t("product.maroon"),
    t("product.navy"),
    t("product.olive"),
    t("product.teal"),
    t("product.gold"),
    t("product.silver"),
    t("product.beige"),
    t("product.coral"),
    t("product.mint"),
    t("product.peach"),
    t("product.other"),
  ];

  return (
    <Box m="20px" mt="0">
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item xs={6}>
          <Header
            title={t("manage_product.product1")}
            subtitle={t("manage_product.listAllProduct")}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignItems={"cneter"}
          >
            <Grid item xs={3}>
              <TextField
                fullWidth
                name="searchType"
                onChange={(e) => {
                  setSearchType(e.target.value);
                }}
                size="small"
                label={t("manage_product.searchType")}
                select
              >
                <MenuItem value={"id"}>{t("manage_product.id")}</MenuItem>
                <MenuItem value={"search"}>{t("manage_product.name")}</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={9}>
              <Box
                display="flex"
                backgroundColor={colors.primary[400]}
                borderRadius="3px"
                gap={1}
              >
                <InputBase
                  onChange={handleSearchChange}
                  sx={{ m: "0em 1em", flex: 1 }}
                  placeholder={t("manage_product.search")}
                />
                <IconButton type="button" sx={{ p: 1 }}>
                  <SearchOutlined />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label={t("manage_product.filterByCategory")}
              name="category"
              size="small"
              select
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              style={{
                backgroundColor:
                  theme.palette.mode === "dark" ? "#272a40" : "#f2f3f5", // Dark mode: #424242, Light mode: #f5f5f5
                borderRadius: "4px", // Optional: Add border-radius for rounded corners
              }}
              InputLabelProps={{
                style: {
                  color: theme.palette.mode === "dark" ? "white" : "gray", // Change label text color based on theme
                },
              }}
            >
              {subcategories?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {isLoading ? (
          t("manage_product.loading")
        ) : (
          <DataGrid
            rows={data?.results && data?.results}
            columns={columns}
            rowHeight={80}
            components={{ Toolbar: GridToolbar }}
            pagination
            pageSize={pageSize}
            rowCount={data?.count}
            page={page}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            loading={loading}
            sx={{ mt: 1 }}
          />
        )}
      </Box>
      {/* Edit dialog */}
      {product && (
        <EditProductDialog
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          product={product}
        />
      )}

      <ConfirmDialog />
    </Box>
  );
};

export default Products;

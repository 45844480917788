// src/web/pages/products/shoppingCart/ShoppingCart.js

import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
  Paper,
  FormLabel,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  Autocomplete,
  Divider,
} from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import { DeleteOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Context from "../../../../context/Context";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context/AuthContext";
import { useTranslation } from "react-i18next";
import useFetchObjects from "../../../../api/useFetchObjects";

const ShoppingCart = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { updateCartItemQuantity, removeCartItem } = useContext(Context);

  // Cart Items and selection state
  const [cartItems, setCartItems] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const afghanPhoneRegex = /^(\+93|0)?(7[0-9]{8})$/;

  const [validationMessages, setValidationMessages] = useState({
    phone: "",
    address: "",
    quantity: {},
    delivery_cost: "",
    payment_to: "", // Renamed from payment_destination
    payment_type: "",
  });
  const [quantityErrorMessage, setQuantityErrorMessage] = useState("");
  const [totalDollarsAmount, setTotalDollarsAmount] = useState(0);
  const [totalAfghanisAmount, setTotalAfghanisAmount] = useState(0);

  // Form Data
  const [formData, setFormData] = useState({
    user: user?.user.id,
    address: "",
    contact: "",
    description: "",
    order_items: [],
    payment_to: "", // Renamed from payment_destination
    payment_type: "",
    delivery_cost: null,
    delivery_price: null,
    argent_price: null,
  });

  const { handleAdd, resetForm } = useAdd(
    "orders",
    token,
    t("product.orderCreatedSuccess"),
    t("product.orderCreateError")
  );

  const {
    data: deliveryCosts,
    isLoading,
    isError,
  } = useFetchObjects(["delivery-costs"], "delivery-costs");

  // Argent Option State
  const [isArgentSelected, setIsArgentSelected] = useState(false);

  // Payment Dialog State
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState(""); // "1" for Asanbawar, "2" for Seller
  const [selectedPaymentType, setSelectedPaymentType] = useState("");

  // ========= Helper Functions =========
  const handleRemoveCartItem = (itemId) => {
    const updatedCartItems = cartItems.filter((item) => item.id !== itemId);
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    removeCartItem(itemId);
  };

  const handleUpdateCartItemQuantity = (itemId, newQuantity) => {
    const item = cartItems.find((i) => i.id === itemId);
    if (newQuantity >= item.whole_price_count) {
      setQuantityErrorMessage(
        `${t("product.greatNews")} ${item?.whole_price_count} ${t(
          "product.unitOf"
        )} ${item?.name}${t("product.qualify")}`
      );
    } else {
      setQuantityErrorMessage("");
    }
    if (newQuantity > item.quantity) {
      setValidationMessages((prev) => ({
        ...prev,
        quantity: {
          ...prev.quantity,
          [itemId]: `${item.name} ${t("product.notEnough")}`,
        },
      }));
    } else {
      setValidationMessages((prev) => ({
        ...prev,
        quantity: { ...prev.quantity, [itemId]: "" },
      }));
    }
    const updatedCartItems = cartItems.map((i) =>
      i.id === itemId ? { ...i, userQuantity: newQuantity } : i
    );
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    updateCartItemQuantity(itemId, newQuantity);
  };

  const handleSelectProduct = (productId, checked) => {
    if (checked) {
      setSelectedProductIds((prev) => [...prev, productId]);
    } else {
      setSelectedProductIds((prev) => prev.filter((id) => id !== productId));
    }
  };

  const handleSelectAll = (checked) => {
    setSelectAll(checked);
    if (checked) {
      const allIds = cartItems.map((item) => item.id);
      setSelectedProductIds(allIds);
    } else {
      setSelectedProductIds([]);
    }
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setValidationMessages((prev) => ({ ...prev, [name]: "" }));
    if (name === "address" || name === "contact") {
      setQuantityErrorMessage("");
    }
  };

  const handleDeliveryCostChange = (event, newValue) => {
    if (newValue) {
      setFormData((prevData) => ({
        ...prevData,
        delivery_cost: newValue.id, // Assuming each delivery cost has a unique `id`
      }));
      setValidationMessages((prev) => ({ ...prev, delivery_cost: "" }));

      // Reset delivery_price and argent_price when delivery cost changes
      setFormData((prevData) => ({
        ...prevData,
        delivery_price: null,
        argent_price: null,
      }));
      setIsArgentSelected(false); // Reset Argent selection when delivery cost changes
    } else {
      setFormData((prevData) => ({
        ...prevData,
        delivery_cost: null,
        delivery_price: null,
        argent_price: null,
      }));
      setIsArgentSelected(false);
    }
  };

  // ========= Payment Dialog Handlers =========
  const handlePaymentDialogOpen = () => {
    if (!formData.delivery_cost) {
      toast.error(t("product.selectDeliveryCost"));
      return;
    }
    if (selectedProductIds.length === 0) {
      toast.error(t("product.selectAtLeastOne"));
      return;
    }
    setOpenPaymentDialog(true);
  };

  const handlePaymentDestinationChange = (e) => {
    setSelectedDestination(e.target.value);
    setValidationMessages((prev) => ({ ...prev, payment_to: "" })); // Renamed from payment_destination
  };

  const handlePaymentTypeChange = (e) => {
    setSelectedPaymentType(e.target.value);
    setValidationMessages((prev) => ({ ...prev, payment_type: "" }));
  };

  const handleArgentChange = (e) => {
    setIsArgentSelected(e.target.checked);
  };

  const handlePaymentDialogClose = () => {
    setOpenPaymentDialog(false);
    setSelectedDestination("");
    setSelectedPaymentType("");
    setIsArgentSelected(false);
  };

  const handlePaymentConfirm = () => {
    let hasError = false;
    if (!selectedDestination) {
      setValidationMessages((prev) => ({
        ...prev,
        payment_to: t("product.selectPaymentDestination"), // Renamed from payment_destination
      }));
      hasError = true;
    }
    if (!selectedPaymentType) {
      setValidationMessages((prev) => ({
        ...prev,
        payment_type: t("product.selectPaymentType"),
      }));
      hasError = true;
    }
    if (hasError) return;

    // Find the selected delivery cost
    const selectedDeliveryCost = deliveryCosts.find(
      (dc) => dc.id === formData.delivery_cost
    );

    if (!selectedDeliveryCost) {
      toast.error(t("product.invalidDeliveryCost"));
      return;
    }

    // Calculate delivery_price based on payment_to
    let deliveryPrice = 0;
    if (selectedDestination === "2") {
      // Payment to Seller
      deliveryPrice = parseFloat(selectedDeliveryCost.from_seller_price) || 0;
    } else if (selectedDestination === "1") {
      // Payment to Asanbawar
      deliveryPrice =
        parseFloat(selectedDeliveryCost.from_asanbawar_price) || 0;
    }

    // Calculate argent_price if Argent is selected
    let argentPrice = 0;
    if (isArgentSelected) {
      argentPrice = parseFloat(selectedDeliveryCost.argent_price) || 0;
    }

    // Calculate order items
    const orderItems = cartItems
      .filter((item) => selectedProductIds.includes(item.id))
      .map((item) => {
        const price =
          item.userQuantity < item.whole_price_count
            ? parseFloat(item.price)
            : parseFloat(item.whole_price);
        return {
          product: item.id,
          quantity: item.userQuantity,
          price,
          user: user?.user.id, // Ensure the correct user ID is set
        };
      });

    // Update formData with calculated prices and payment details
    const updatedFormData = {
      ...formData,
      payment_to: selectedDestination, // Renamed from payment_destination
      payment_type: selectedPaymentType,
      delivery_price: deliveryPrice,
      argent_price: isArgentSelected ? argentPrice : null,
      order_items: orderItems,
    };

    // Update formData state
    setFormData(updatedFormData);
    setOpenPaymentDialog(false);
  };

  // ========= useEffect to Reset on Successful Order =========
  useEffect(() => {
    if (resetForm) {
      // Remove only the ordered products from the cart.
      const remainingItems = cartItems.filter(
        (item) => !selectedProductIds.includes(item.id)
      );
      setCartItems(remainingItems);
      localStorage.setItem("cartItems", JSON.stringify(remainingItems));
      setSelectedProductIds([]);
      setSelectAll(false);
      setFormData({
        user: user?.user.id,
        address: "",
        contact: "",
        description: "",
        order_items: [],
        payment_to: "", // Renamed from payment_destination
        payment_type: "",
        delivery_cost: null,
        delivery_price: null,
        argent_price: null,
      });
      setIsArgentSelected(false);
      setQuantityErrorMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm]);

  // ========= useEffect to Load Cart Items =========
  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartItems(storedCartItems);
  }, []);

  // ========= useEffect to Calculate Total Amounts =========
  useEffect(() => {
    if (selectedProductIds.length === 0) {
      setTotalDollarsAmount(0);
      setTotalAfghanisAmount(0);
      return;
    }

    let dollarTotal = 0;
    let afghaniTotal = 0;

    const selectedItems = cartItems.filter((item) =>
      selectedProductIds.includes(item.id)
    );

    selectedItems.forEach((item) => {
      const price =
        item.userQuantity < item.whole_price_count
          ? parseFloat(item.price)
          : parseFloat(item.whole_price);
      if (item.currency === 1) {
        afghaniTotal += item.userQuantity * price;
      } else {
        dollarTotal += item.userQuantity * price;
      }
    });

    // Add delivery_price and argent_price if they exist
    if (formData.delivery_price !== null) {
      // Assuming delivery costs are in AFN; adjust if currency varies
      afghaniTotal += formData.delivery_price;
    }

    if (formData.argent_price !== null) {
      afghaniTotal += formData.argent_price;
    }

    setTotalDollarsAmount(dollarTotal);
    setTotalAfghanisAmount(afghaniTotal);
  }, [
    cartItems,
    selectedProductIds,
    formData.delivery_price,
    formData.argent_price,
    deliveryCosts,
    formData.delivery_cost,
  ]);

  const handleCheckout = () => {
    // Validate inputs
    let hasError = false;
    if (!afghanPhoneRegex.test(formData.contact)) {
      setValidationMessages((prev) => ({
        ...prev,
        phone: t("product.phoneNum"),
      }));
      hasError = true;
    }
    if (!formData.address) {
      setValidationMessages((prev) => ({
        ...prev,
        address: t("product.enterAddress"),
      }));
      hasError = true;
    }
    if (!formData.delivery_cost) {
      setValidationMessages((prev) => ({
        ...prev,
        delivery_cost: t("product.selectDeliveryCost"),
      }));
      hasError = true;
    }
    if (!formData.payment_to) {
      // Renamed from payment_destination
      setValidationMessages((prev) => ({
        ...prev,
        payment_to: t("product.selectPaymentDestination"),
      }));
      hasError = true;
    }
    if (!formData.payment_type) {
      setValidationMessages((prev) => ({
        ...prev,
        payment_type: t("product.selectPaymentType"),
      }));
      hasError = true;
    }
    for (const item of cartItems) {
      if (validationMessages.quantity[item.id]) {
        setQuantityErrorMessage(validationMessages.quantity[item.id]);
        hasError = true;
        break;
      }
    }
    if (selectedProductIds.length === 0) {
      toast.error(t("product.selectAtLeastOne"));
      hasError = true;
    }
    if (hasError) {
      return;
    }

    // Ensure that delivery_price and argent_price are set
    if (formData.delivery_price === null) {
      toast.error(t("product.deliveryPricePending"));
      return;
    }

    // Proceed with submitting the order
    handleAdd(formData);
  };

  // ========= Rendering =========
  // Handle loading and error states for delivery costs
  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Typography variant="h6">{t("product.loading")}</Typography>
      </Grid>
    );
  }

  if (isError) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Typography color="error" variant="h6">
          {t("product.errorFetchingDeliveryCosts")}
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ minHeight: "100vh", padding: theme.spacing(2) }}
      >
        {/* Shopping Cart Section */}
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Shopping Cart Title */}
          <Typography mt={2} variant="h4" textAlign="left" fontWeight="bold">
            {t("product.shoppingCart")}
          </Typography>

          {/* Product Items */}
          <Box flexGrow={1} mt={2}>
            {quantityErrorMessage && (
              <Typography color="error" variant="body1" mb={2}>
                {quantityErrorMessage}
              </Typography>
            )}
            <TableContainer component={Paper} sx={{ maxHeight: "75vh" }}>
              <Table stickyHeader>
                <TableHead sx={{ bgcolor: "#dee3e0" }}>
                  <TableRow>
                    <TableCell align="center">
                      <Checkbox
                        checked={selectAll}
                        onChange={(e) => handleSelectAll(e.target.checked)}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell align="center">{t("product.product")}</TableCell>
                    <TableCell align="center">
                      {t("product.retailPrice")}
                    </TableCell>
                    <TableCell align="center">
                      {t("product.wholePrice")}
                    </TableCell>
                    <TableCell align="center">
                      {t("product.quantity")}
                    </TableCell>
                    <TableCell align="center">{t("product.inStock")}</TableCell>
                    <TableCell align="center">{t("product.total")}</TableCell>
                    <TableCell align="center">{t("product.action")}</TableCell>
                    <TableCell align="center">{t("product.chat")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cartItems.map((product) => (
                    <TableRow key={product.id} hover>
                      <TableCell align="center">
                        <Checkbox
                          checked={selectedProductIds.includes(product.id)}
                          onChange={(e) =>
                            handleSelectProduct(product.id, e.target.checked)
                          }
                          color="primary"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Box display="flex" alignItems="center">
                          <Box mr={2}>
                            <img
                              src={
                                product.images && product.images.length > 0
                                  ? product.images[0].image
                                  : "https://via.placeholder.com/80"
                              }
                              alt={product.name}
                              style={{
                                width: "80px",
                                height: "80px",
                                objectFit: "cover",
                                borderRadius: "8px",
                              }}
                            />
                          </Box>
                          <Typography variant="body1" fontWeight="bold">
                            {product.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>
                          {product.price !== null
                            ? `${parseFloat(product.price).toFixed(2)} ${
                                product.currency === 1 ? "؋" : "$"
                              }`
                            : "0.00"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>
                          {product.whole_price !== null
                            ? `${parseFloat(product.whole_price).toFixed(2)} ${
                                product.currency === 1 ? "؋" : "$"
                              }`
                            : "0.00"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          variant="outlined"
                          size="small"
                          type="number"
                          value={product.userQuantity}
                          onChange={(e) =>
                            handleUpdateCartItemQuantity(
                              product.id,
                              parseInt(e.target.value, 10)
                            )
                          }
                          sx={{ maxWidth: { sm: "10vw", md: "7vw" } }}
                          error={!!validationMessages.quantity[product.id]}
                          helperText={validationMessages.quantity[product.id]}
                          inputProps={{ min: 1 }}
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        {product.quantity}
                      </TableCell>
                      <TableCell align="center">
                        <Typography>
                          {product.userQuantity >= product.whole_price_count &&
                          parseFloat(product.whole_price) !== 0
                            ? `${(
                                parseFloat(product.whole_price) *
                                product.userQuantity
                              ).toFixed(2)} ${
                                product.currency === 1 ? "؋" : "$"
                              }`
                            : `${(
                                parseFloat(product.price) * product.userQuantity
                              ).toFixed(2)} ${
                                product.currency === 1 ? "؋" : "$"
                              }`}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => handleRemoveCartItem(product.id)}
                          color="error"
                        >
                          <DeleteOutline />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() =>
                            navigate("/chat", { state: { product } })
                          }
                          color="primary"
                        >
                          <MessageIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>

        {/* Summary Cart Section */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              position: "sticky",
              top: theme.spacing(2),
              backgroundColor: "#fafafa",
            }}
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              gutterBottom
              sx={{ borderBottom: "3px solid green", pb: 1 }}
              textAlign="center"
            >
              {t("product.cartSummary")}
            </Typography>

            <Box flexGrow={1} mt={2} overflow="auto">
              {/* Total Amounts */}
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography variant="body1" fontWeight="bold">
                  {t("product.totalUsd")}:
                </Typography>
                <Typography fontWeight="bold">
                  {totalDollarsAmount.toFixed(2)} $
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="body1" fontWeight="bold">
                  {t("product.totalAfn")}:
                </Typography>
                <Typography fontWeight="bold">
                  {totalAfghanisAmount.toFixed(2)} ؋
                </Typography>
              </Box>

              {/* Address Input */}
              <Box mb={2}>
                <FormLabel>{t("product.address")}</FormLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={t("product.writeAddress")}
                  name="address"
                  value={formData.address}
                  onChange={handleChangeInput}
                  InputProps={{ inputProps: { min: 0 } }}
                  rows={2}
                  multiline
                  error={!!validationMessages.address}
                  helperText={validationMessages.address}
                />
              </Box>

              {/* Contact Input */}
              <Box mb={2}>
                <FormLabel>{t("product.activeNum")}</FormLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="contact"
                  value={formData.contact}
                  onChange={handleChangeInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+93</InputAdornment>
                    ),
                  }}
                  error={!!validationMessages.phone}
                  helperText={validationMessages.phone}
                />
              </Box>

              {/* Description Input */}
              <Box mb={2}>
                <FormLabel>{t("product.description")}</FormLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={t("product.writeDescription")}
                  name="description"
                  value={formData.description}
                  onChange={handleChangeInput}
                  InputProps={{ inputProps: { min: 0 } }}
                  rows={3}
                  multiline
                />
              </Box>

              {/* Autocomplete for Delivery Costs */}
              <Box mb={2}>
                <FormLabel>{t("product.deliveryCost")}</FormLabel>
                <Autocomplete
                  options={deliveryCosts || []}
                  getOptionLabel={(option) =>
                    `${option.origin} to ${option.destination}`
                  }
                  onChange={handleDeliveryCostChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      placeholder={t("product.selectDeliveryCost")}
                      error={!!validationMessages.delivery_cost}
                      helperText={validationMessages.delivery_cost}
                    />
                  )}
                  sx={{ mt: 1 }}
                />
              </Box>

              {/* Payment Destination and Type Selection */}
              <Box mb={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handlePaymentDialogOpen}
                  disabled={
                    !formData.delivery_cost || selectedProductIds.length === 0
                  }
                >
                  {t("product.selectPaymentMethod")}
                </Button>
              </Box>

              {/* Argent Option */}
              <Box mb={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isArgentSelected}
                      onChange={handleArgentChange}
                      color="primary"
                    />
                  }
                  label={t("product.useArgent")}
                />
              </Box>

              {/* Display Selected Delivery Cost and Prices */}
              {selectedProductIds.length > 0 && formData.delivery_cost && (
                <Box mb={2}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    {t("product.selectedDeliveryCost")}:
                  </Typography>
                  <Typography variant="body2">
                    {
                      deliveryCosts.find(
                        (dc) => dc.id === formData.delivery_cost
                      )?.origin
                    }{" "}
                    to{" "}
                    {
                      deliveryCosts.find(
                        (dc) => dc.id === formData.delivery_cost
                      )?.destination
                    }
                  </Typography>
                  <Box mt={1}>
                    <Typography variant="body2" fontWeight="bold">
                      {t("product.deliveryPrice")}:
                    </Typography>
                    {formData.delivery_price !== null ? (
                      <Typography variant="body2">
                        {`${formData.delivery_price.toFixed(2)} ${
                          "؋" // Assuming AFN; adjust if currency varies
                        }`}
                      </Typography>
                    ) : (
                      <Typography variant="body2">-</Typography>
                    )}
                  </Box>
                  {/* Updated Argent Price Display */}
                  {formData.argent_price !== null &&
                    formData.argent_price > 0 && (
                      <Box mt={1}>
                        <Typography variant="body2" fontWeight="bold">
                          {t("product.argentPrice")}:
                        </Typography>
                        <Typography variant="body2">
                          {`${formData.argent_price.toFixed(2)} ${
                            "؋" // Assuming AFN; adjust if currency varies
                          }`}
                        </Typography>
                      </Box>
                    )}
                </Box>
              )}

              {/* Checkout Button */}
              <Box mt={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={handleCheckout}
                  disabled={
                    !formData.delivery_cost ||
                    selectedProductIds.length === 0 ||
                    !formData.payment_to || // Renamed from payment_destination
                    !formData.payment_type
                  }
                >
                  {t("product.checkout")}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Payment Dialog Outside the Grid Container */}
      <Dialog
        open={openPaymentDialog}
        onClose={handlePaymentDialogClose}
        PaperProps={{
          sx: {
            borderRadius: 3,
            padding: 3,
            backgroundColor: "#f7f7f7",
            boxShadow: 5,
            maxWidth: 500,
            width: "90%",
          },
        }}
      >
        <DialogTitle sx={{ fontWeight: "bold", textAlign: "center", mb: 2 }}>
          {t("product.selectPaymentMethod")}
        </DialogTitle>
        <Divider />
        <DialogContent dividers>
          {/* Payment Destination Section */}
          <Box mb={3}>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
              {t("product.destination")}
            </Typography>
            <RadioGroup
              value={selectedDestination}
              onChange={handlePaymentDestinationChange}
              row
            >
              <FormControlLabel
                value="1"
                control={<Radio color="primary" />}
                label={t("product.toAsanbawar")}
              />
              <FormControlLabel
                value="2"
                control={<Radio color="primary" />}
                label={t("product.toSeller")}
              />
            </RadioGroup>
            {validationMessages.payment_to && ( // Renamed from payment_destination
              <Typography color="error" variant="body2">
                {validationMessages.payment_to}
              </Typography>
            )}
          </Box>

          {/* Payment Type Section */}
          <Box mb={3}>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
              {t("product.selectPaymentType")}
            </Typography>
            <RadioGroup
              value={selectedPaymentType}
              onChange={handlePaymentTypeChange}
              row
            >
              <FormControlLabel
                value="1"
                control={<Radio color="primary" />}
                label={t("product.cash")}
              />
              <FormControlLabel
                value="2"
                control={<Radio color="primary" />}
                label={t("product.online")}
              />
            </RadioGroup>
            {validationMessages.payment_type && (
              <Typography color="error" variant="body2">
                {validationMessages.payment_type}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
          <Button
            onClick={handlePaymentConfirm}
            variant="contained"
            color="primary"
            disabled={!(selectedDestination && selectedPaymentType)}
            sx={{ minWidth: 120 }}
          >
            {t("product.accept")} (
            {selectedPaymentType === "1"
              ? t("product.cash")
              : selectedPaymentType === "2"
              ? t("product.online")
              : "N/A"}
            )
          </Button>
          <Button
            onClick={handlePaymentDialogClose}
            variant="outlined"
            color="secondary"
            sx={{ minWidth: 120 }}
          >
            {t("product.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShoppingCart;

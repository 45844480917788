import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  MenuItem,
  TextField,
  IconButton,
  InputBase,
} from "@mui/material";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useFetchObjects from "../../../api/useFetchObjects";
import useDelete from "../../../api/useDelete";
import ErrorPage from "../../../components/errorPage/ErrorPage";
import { Add, SearchOutlined } from "@mui/icons-material";
import { useAuth } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";
import EditAd from "./EditAd";
import AddAd from "./AddAd";
import useUpdate from "../../../api/useUpdate";

const Advertisements = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const auth = useAuth();
  const user = auth?.user;
  const token = auth?.user?.token;

  const [ad, setAd] = useState({});
  const [page, setPage] = useState(0);
  const [searchType, setSearchType] = useState("search");
  const [userInfo, setUserInfo] = useState(null);
  const [search, setSearch] = useState("");
  const [openEdit, setOpenEdit] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["Advertisements", page],
    `advertisement/?${searchType}=${search}`,
    token
  );
  console.log(data)
  useEffect(() => {
    refetch();
  }, [filter, refetch, search, searchType]);

  // Update and ad
  const { handleUpdate, loading: loadingUpdate, isSuccess } = useUpdate(
    "advertisement",
    token,
    "/dashboard/advertisements"
  );

  const columns = [
    { field: "id", headerName: t("ad.id"), flex: 0.5 },
    {
      field: "title",
      headerName: t("ad.title"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: t("ad.description"),
      headerAlign: "left",
      align: "left",
    },

    {
      field: "external_link",
      headerName: t("ad.link"),
      flex: 1,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      flex: 1,
      renderCell: (params) => (
        <div>
          <span>{params.row?.start_date.split("T")[0]}</span>
        </div>
      ),
    },
    {
      field: "end_date",
      headerName: "End Date",
      flex: 1,
      renderCell: (params) => (
        <div>
          <span>{params.row?.end_date.split("T")[0]}</span>
        </div>
      ),
    },

    {
      field: "actions",
      headerName: t("ad.actions"),
      flex: 2,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setAd(params.row);
              setOpenEdit(true);
            }}
          >
            {t("ad.edit")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDelete(params.row.id)}
            style={{ margin: "0em 1em" }}
          >
            {t("ad.delete")}
          </Button>
          <Box>
            {params.row.is_active ? (
              <Button onClick={() => handleUpdate(params.row.id, {is_active: false})} color="secondary" variant="contained">
                Active
              </Button>
            ) : (
              <Button onClick={() => handleUpdate(params.row.id, {is_active: true})} color="secondary" variant="outlined">
                Inactive
              </Button>
            )}
          </Box>
        </Stack>
      ),
    },
  ];


  const { handleDelete, ConfirmDialog } = useDelete(`advertisement`, token);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <Box m="20px" mt="0">
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <Header
            title={t("ad.ads")}
            subtitle={t("ad.listOfAds")}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignItems={"cneter"}
          >
            <Grid item xs={3}>
              <TextField
                fullWidth
                name="searchType"
                onChange={(e) => {
                  setSearchType(e.target.value);
                }}
                size="small"
                label={t("manage_product.searchType")}
                select
              >
                <MenuItem value={"id"}>{t("ad.id")}</MenuItem>
                <MenuItem value={"search"}>{t("ad.name")}</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={9}>
              <Box
                display="flex"
                backgroundColor={colors.primary[400]}
                borderRadius="3px"
                gap={1}
              >
                <InputBase
                  onChange={handleSearchChange}
                  sx={{ m: "0em 1em", flex: 1 }}
                  placeholder={t("manage_product.search")}
                />
                <IconButton type="button" sx={{ p: 1 }}>
                  <SearchOutlined />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Grid sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}} item xs={12}>
            <Box></Box>
            <Button
              onClick={() => setOpenAdd(true)}
              variant="contained"
              color="secondary"
              sx={{ textTransform: "capitalize", fontWeight: "bold" }}
            >
              &nbsp; {t("ad.newAd")} &nbsp;
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Box
        m="40px 0 0 0"
        mt={0}
        height="72vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {isLoading ? (
          t("manage_product.loading")
        ) : (
          <DataGrid
            rows={data && data}
            columns={columns}
            rowHeight={80}
            components={{ Toolbar: GridToolbar }}
            loading={isLoading}
          />
        )}
      </Box>

      {/* Delete Ad */}
      <ConfirmDialog />

      {openEdit && (
        <EditAd ad={ad} open={openEdit} onClose={() => setOpenEdit(false)} data={ad} />
      )}
      {openAdd && <AddAd open={openAdd} onClose={() => setOpenAdd(false)} />}
    </Box>
  );
};

export default Advertisements;

// src/api/useFetchObject.js
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const useFetchObject = (queryKey, endpoint, id, token) => {
  const fetchFunction = async () => {
    const headers = token ? { Authorization: `Token ${token}` } : {};
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/${endpoint}/${id}/`,
      {
        headers,
      }
    );
    return response.data;
  };

  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: [queryKey],
    queryFn: fetchFunction,
  });

  return { data, isLoading, isError, error, refetch };
};

export default useFetchObject;

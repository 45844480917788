import React from "react";
import useFetchObjects from "../../../api/useFetchObjects";
import { useAuth } from "../../../context/AuthContext";
import {
  Container,
  Box,
  Typography,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Avatar,
  Chip,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SkeletonLoading from "../../../components/skeletonLoading/SkeletonLoading";
import ErrorPage from "../../../components/errorPage/ErrorPage";
import NoData from "../../../components/noData/NoData";
import DateFormatter from "../../../components/DateFormatter/DateFormatter";
import useDelete from "../../../api/useDelete";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PaymentIcon from "@mui/icons-material/Payment";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const getStatusStep = (status) => {
  switch (status) {
    case 1: // Pending
      return 0;
    case 3: // Accepted
      return 1;
    case 4: // In Progress
      return 2;
    case 5: // Delivered
      return 3;
    default:
      return -1; // Invalid or canceled status
  }
};

// Formatter for AFG currency
const formatAFG = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "AFN",
  }).format(amount);
};

// Formatter for USD currency
const formatUSD = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

const BuyerOrders = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const location = useLocation();
  const orderID = location?.state?.id;
  const lang = localStorage.getItem("i18nextLng");

  const { data, isError, isLoading } = useFetchObjects(
    ["orders"],
    `orders/?user=${user?.user?.id}&id=${orderID || ""}`,
    token
  );
  const { handleDelete, ConfirmDialog } = useDelete("orders", token);

  const steps = [
    {
      label: t("order.orderPlaced"),
      description: t("order.placedMessage"),
      icon: <ShoppingCartIcon />,
    },
    {
      label: t("order.accepted"),
      description: t("order.acceptedMessage"),
      icon: <LocalShippingIcon />,
    },
    {
      label: t("order.progress"),
      description: t("order.progressMessage"),
      icon: <LocalShippingIcon />,
    },
    {
      label: t("order.delivered"),
      description: t("order.deliveredMessage"),
      icon: <LocalShippingIcon />,
    },
  ];

  if (isLoading) {
    return (
      <Grid container mt={1} padding={"20px"} spacing={2}>
        {Array.from({ length: 3 }).map((_, index) => (
          <Grid item xs={12} key={index}>
            <SkeletonLoading height={200} />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (isError || !data) {
    return (
      <Grid container>
        <ErrorPage />
      </Grid>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h2" mb={4} textAlign="center">
          {t("order.yourOrder")}
        </Typography>

        {(!data || data?.length === 0) && (
          <Grid container justifyContent="center">
            <NoData message={t("order.dontOrder")} />
          </Grid>
        )}

        <Grid container spacing={4}>
          {data.map((order) => (
            <Grid item xs={12} key={order.id}>
              <Box
                sx={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                  overflow: "hidden",
                  padding: 2,
                }}
              >
                {/* Header Section */}
                <Box
                  sx={{
                    backgroundColor: "#f5f5f5",
                    color: "#333",
                    p: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "8px",
                  }}
                >
                  <Typography variant="h6" fontWeight="bold">
                    {t("order.orderId")}: #{order.id}
                  </Typography>

                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => handleDelete(order.id)}
                  >
                    {t("order.delete")}
                  </Button>
                </Box>

                {/* Order Summary */}
                <Box mt={2} mb={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography variant="h6" fontWeight="500">
                          {t("order.paymentType")}:{" "}
                          {order.payment_type === 1
                            ? t("order.cash")
                            : t("order.online")}
                        </Typography>
                      </Box>

                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography variant="h6" fontWeight="500">
                          {t("product.destination")}:{" "}
                          {order.payment_to === 1
                            ? t("product.toAsanbawar")
                            : t("product.toSeller")}
                        </Typography>
                      </Box>

                      <Typography variant="body1">
                        <strong>{t("order.date")}:</strong>{" "}
                        {DateFormatter(
                          null,
                          order?.order_date,
                          order?.order_time
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Typography variant="body1">
                          <strong>{t("order.totalAmountAFG")}:</strong>{" "}
                          {order.total_amount_afg
                            ? formatAFG(order.total_amount_afg)
                            : 0}
                        </Typography>
                        <Typography variant="body1">
                          <strong>{t("order.deliveryPrice")}:</strong>{" "}
                          {order.delivery_price
                            ? formatAFG(order.delivery_price)
                            : 0}
                        </Typography>
                        <Typography variant="body1">
                          <strong>
                            {t("order.argentPrice", "Argent Price")}:
                          </strong>{" "}
                          {order.argent_price
                            ? formatAFG(order.argent_price)
                            : 0}
                        </Typography>
                        <Typography variant="body1">
                          <strong>{t("order.totalAFG", "Total AFG")}: </strong>{" "}
                          {formatAFG(
                            (Number(order.total_amount_afg) || 0) +
                              (Number(order.delivery_price) || 0) +
                              (Number(order.argent_price) || 0)
                          )}
                        </Typography>
                        <Typography variant="body1">
                          <strong>{t("order.totalAmountUSD")}:</strong>{" "}
                          {order.total_amount_usd
                            ? formatUSD(order.total_amount_usd)
                            : 0}
                        </Typography>
                        <div dir="ltr">
                          <Box mt={1} sx={{ direction: "ltr" }}>
                            <Chip
                              label={
                                order.status === 2
                                  ? t("order.canceled")
                                  : t(`order.status${order.status}`)
                              }
                              sx={{ direction: "ltr" }}
                              color={
                                order.status === 1
                                  ? "warning"
                                  : order.status === 3
                                  ? "info"
                                  : order.status === 4
                                  ? "secondary"
                                  : order.status === 5
                                  ? "success"
                                  : "default"
                              }
                              size="medium"
                              variant="outlined"
                            />
                          </Box>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                {/* Stepper for Order Status */}
                {order.status !== 2 && (
                  <Box mb={4}>
                    <Stepper
                      activeStep={getStatusStep(order.status)}
                      alternativeLabel
                      connector={
                        <StepConnector
                          sx={{
                            [`& .MuiStepConnector-line`]: {
                              borderColor: "#bdbdbd",
                              height: 3,
                            },
                          }}
                        />
                      }
                    >
                      {steps.map((step, index) => (
                        <Step key={step.label}>
                          <StepLabel
                            StepIconComponent={() => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: 40,
                                  height: 40,
                                  borderRadius: "50%",
                                  backgroundColor:
                                    getStatusStep(order.status) >= index
                                      ? "#1976d2"
                                      : "#bdbdbd",
                                  color: "#fff",
                                }}
                              >
                                {step.icon}
                              </Box>
                            )}
                          >
                            <Typography variant="body1" fontWeight="500">
                              {step.label}
                            </Typography>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                )}

                <Divider />

                {/* Detailed Information */}
                <Box mt={3}>
                  {/* Delivery Information */}
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="delivery-info-content"
                      id="delivery-info-header"
                    >
                      <Typography variant="subtitle1" fontWeight="600">
                        {t("order.deliveryInformation")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body1">
                            <strong>{t("order.address")}:</strong>{" "}
                            {order.address || "N/A"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body1">
                            <strong>{t("order.activeNumber")}:</strong>{" "}
                            {order.contact || "N/A"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body1">
                            <strong>{t("order.argentPrice")}:</strong>{" "}
                            {order.argent_price
                              ? formatAFG(order.argent_price)
                              : "N/A"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body1">
                            <strong>{t("order.deliveryPrice")}:</strong>{" "}
                            {order.delivery_price
                              ? formatAFG(order.delivery_price)
                              : "N/A"}
                          </Typography>
                        </Grid>
                        {/* Delivery Cost Details */}
                        {order.delivery_cost_details && (
                          <Grid item xs={12}>
                            <Box mt={2}>
                              <Typography
                                variant="subtitle2"
                                fontWeight="600"
                                mb={1}
                              >
                                {t("order.deliveryCostDetails")}
                              </Typography>
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                  <Typography variant="body1">
                                    <strong>{t("order.origin")}:</strong>{" "}
                                    {order.delivery_cost_details.origin}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography variant="body1">
                                    <strong>{t("order.destination")}:</strong>{" "}
                                    {order.delivery_cost_details.destination}
                                  </Typography>
                                </Grid>

                                {/* Add more delivery cost details if available */}
                              </Grid>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Divider />

                  {/* Placed By Information */}
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="placed-by-info-content"
                      id="placed-by-info-header"
                    >
                      <Typography variant="subtitle1" fontWeight="600">
                        {t("order.placedBy")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Avatar
                            alt={`${order.display_user.firstname} ${order.display_user.lastname}`}
                            src={
                              order.display_user.profile_picture ||
                              "/default-avatar.png"
                            }
                            sx={{ width: 56, height: 56 }}
                          />
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body1">
                            <strong>{t("order.name")}:</strong>{" "}
                            {order.display_user.firstname}{" "}
                            {order.display_user.lastname}
                          </Typography>
                          <Typography variant="body1">
                            <strong>{t("order.email")}:</strong>{" "}
                            {order.display_user.email}
                          </Typography>
                          <Typography variant="body1">
                            <strong>{t("order.phoneNumber")}:</strong>{" "}
                            {order.display_user.phone_number}
                          </Typography>
                          <Typography variant="body1">
                            <strong>{t("order.address")}:</strong>{" "}
                            {order.display_user.address || "N/A"}
                          </Typography>
                          {/* Additional Shop Information */}
                          {order.display_user.shop_name && (
                            <Typography variant="body1">
                              <strong>{t("order.shopName")}:</strong>{" "}
                              {order.display_user.shop_name}
                            </Typography>
                          )}
                          {order.display_user.shop_address && (
                            <Typography variant="body1">
                              <strong>{t("order.shopAddress")}:</strong>{" "}
                              {order.display_user.shop_address}
                            </Typography>
                          )}
                          {/* Add more shop-related information if available */}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Divider />

                  {/* Order Items */}
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="order-items-content"
                      id="order-items-header"
                    >
                      <Typography variant="subtitle1" fontWeight="600">
                        {t("order.orderItems")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={4}>
                        {order.order_items_response.map((item) => (
                          <Grid item xs={12} key={item.id}>
                            <Box
                              sx={{
                                border: "1px solid #e0e0e0",
                                borderRadius: "8px",
                                padding: 2,
                              }}
                            >
                              <Grid container spacing={2}>
                                {/* Product Image */}
                                <Grid item xs={12} sm={3}>
                                  <Box
                                    component="img"
                                    src={
                                      item.product.images[0]?.image
                                        ? `${process.env.REACT_APP_IMAGE_URL1}${item.product.images[0].image}`
                                        : "/placeholder.png"
                                    }
                                    alt={
                                      item.product.dari_name || "Product Image"
                                    }
                                    sx={{
                                      width: "100%",
                                      height: "auto",
                                      borderRadius: "8px",
                                      objectFit: "cover",
                                    }}
                                  />
                                </Grid>
                                {/* Product Details */}
                                <Grid item xs={12} sm={9}>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    mb={1}
                                  >
                                    <Typography variant="h6" fontWeight="600">
                                      {lang === "en" && item.product.name}
                                      {lang === "ps" && item.product.dari_name}
                                      {lang === "dr" &&
                                        item.product.pashto_name}
                                    </Typography>
                                    <Chip
                                      label={
                                        (lang === "en" &&
                                          item.product.category?.name) ||
                                        "Uncategorized" ||
                                        (lang === "ps" &&
                                          item.product.category?.pashto_name) ||
                                        "Uncategorized" ||
                                        (lang === "dr" &&
                                          item.product.category?.dari_name) ||
                                        "Uncategorized"
                                      }
                                      color="primary"
                                      size="small"
                                    />
                                  </Box>
                                  <Typography
                                    variant="body1"
                                    color="text.secondary"
                                    mb={2}
                                  >
                                    {(lang === "en" &&
                                      item.product.description) ||
                                      "No description available."}

                                    {(lang === "dr" &&
                                      item.product.dari_description) ||
                                      "حزیات در دسترسی نیست"}

                                    {(lang === "ps" &&
                                      item.product.pashto_description) ||
                                      "حزیات در دسترسی نیست"}
                                  </Typography>
                                  <Grid container spacing={2}>
                                    <Grid item xs={6} sm={3}>
                                      <Typography variant="body2">
                                        <strong>{t("order.price")}:</strong>{" "}
                                        {item.price
                                          ? formatAFG(item.price)
                                          : "N/A"}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                      <Typography variant="body2">
                                        <strong>{t("order.quantity")}:</strong>{" "}
                                        {item.quantity}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                      <Typography variant="body2">
                                        <strong>
                                          {t("order.wholePrice")}:
                                        </strong>{" "}
                                        {item.whole_price
                                          ? formatAFG(item.whole_price)
                                          : "N/A"}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                      <Typography variant="body2">
                                        <strong>
                                          {t("order.wholePriceCount")}:
                                        </strong>{" "}
                                        {item.whole_price_count}
                                      </Typography>
                                    </Grid>
                                    {/* Colors */}
                                    {item.product.colors &&
                                      item.product.colors.length > 0 && (
                                        <Grid item xs={12} sm={6}>
                                          <Typography variant="body2">
                                            <strong>
                                              {t("order.colors")}:
                                            </strong>{" "}
                                            {item.product.colors.map(
                                              (color) => (
                                                <Chip
                                                  key={color.id}
                                                  label={color.name}
                                                  size="small"
                                                  sx={{ mr: 0.5, mb: 0.5 }}
                                                />
                                              )
                                            )}
                                          </Typography>
                                        </Grid>
                                      )}
                                    {/* Sizes */}
                                    {item.product.sizes &&
                                      item.product.sizes.length > 0 && (
                                        <Grid item xs={12} sm={6}>
                                          <Typography variant="body2">
                                            <strong>{t("order.sizes")}:</strong>{" "}
                                            {item.product.sizes.map((size) => (
                                              <Chip
                                                key={size.id}
                                                label={size.size}
                                                size="small"
                                                sx={{ mr: 0.5, mb: 0.5 }}
                                              />
                                            ))}
                                          </Typography>
                                        </Grid>
                                      )}
                                  </Grid>

                                  {/* Product Owner Information */}
                                  <Box mt={3}>
                                    <Typography
                                      variant="subtitle2"
                                      fontWeight="600"
                                      mb={1}
                                    >
                                      {t("order.productOwner")}
                                    </Typography>
                                    <Grid
                                      container
                                      spacing={2}
                                      alignItems="center"
                                    >
                                      <Grid item>
                                        <Avatar
                                          alt={`${item.product.display_user.first_name} ${item.product.display_user.last_name}`}
                                          src={
                                            item.product.display_user
                                              .profile_picture ||
                                            "/default-avatar.png"
                                          }
                                          sx={{ width: 48, height: 48 }}
                                        />
                                      </Grid>
                                      <Grid item xs>
                                        <Typography variant="body1">
                                          <strong>{t("order.name")}:</strong>{" "}
                                          {item.product.display_user.first_name}{" "}
                                          {item.product.display_user.last_name}
                                        </Typography>
                                        <Typography variant="body1">
                                          <strong>{t("order.email")}:</strong>{" "}
                                          {item.product.display_user.email}
                                        </Typography>
                                        <Typography variant="body1">
                                          <strong>
                                            {t("order.phoneNumber")}:
                                          </strong>{" "}
                                          {
                                            item.product.display_user
                                              .phone_number
                                          }
                                        </Typography>
                                        <Typography variant="body1">
                                          <strong>{t("order.address")}:</strong>{" "}
                                          {item.product.display_user.address ||
                                            "N/A"}
                                        </Typography>
                                        {/* Additional Shop Information */}
                                        {item.product.display_user
                                          .shop_name && (
                                          <Typography variant="body1">
                                            <strong>
                                              {t("order.shopName")}:
                                            </strong>{" "}
                                            {
                                              item.product.display_user
                                                .shop_name
                                            }
                                          </Typography>
                                        )}
                                        {item.product.display_user
                                          .shop_address && (
                                          <Typography variant="body1">
                                            <strong>
                                              {t("order.shopAddress")}:
                                            </strong>{" "}
                                            {
                                              item.product.display_user
                                                .shop_address
                                            }
                                          </Typography>
                                        )}
                                        {/* Add more shop-related information if available */}
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <ConfirmDialog />
    </Container>
  );
};

export default BuyerOrders;

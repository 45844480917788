import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  LinearProgress,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../context/AuthContext";
import { CloudUpload } from "@mui/icons-material";
import useAdd from "../../../api/useAdd";
import useFetchObjects from "../../../api/useFetchObjects";
import Autocomplete from "@mui/material/Autocomplete";

const AddPost = ({ open, onClose }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  // Internal state
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    link: "",
    image: null,
    product: null,
  });

  const [formErrors, setFormErrors] = useState({
    title: "",
  });

  const [fileInfo, setFileInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data: products = [], isLoading: isLoadingProducts } = useFetchObjects(
    ["Products"],
    `products`,
    token
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      setTimeout(() => {
        setFileInfo({
          file: file,
          name: file.name,
        });
        setLoading(false);
      }, 1000);
    }
  };

  const { handleAdd, loading: loadingUpload } = useAdd(
    "posts",
    token,
    "Advertisement added successfully",
    "Failed to add Advertisement"
  );

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.title.trim()) {
      errors.title = "Title is required.";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      setLoading(true);

      const sendData = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        if (value !== null) {
          sendData.append(key, value);
        }
      });

      if (fileInfo?.file) {
        sendData.append("image", fileInfo.file);
      }

      await handleAdd(sendData);
      setLoading(false);
      onClose();
    } catch (error) {
      alert("Failed to submit the form. Please try again.");
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <DialogTitle
          sx={{
            textAlign: "center",
            backgroundColor: "#3f51b5",
            color: "#fff",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">{t("manage_product.edit")}</Typography>
        </DialogTitle>
        <DialogContent
          sx={{ padding: 2, maxHeight: "65vh", overflowY: "auto" }}
        >
          <Grid mt={1} container spacing={3}>
            {/* Title */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label={t("post.title")}
                name="title"
                value={formData.title}
                onChange={handleChange}
                error={!!formErrors.title}
                helperText={formErrors.title}
              />
            </Grid>

            {/* Description */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label={t("post.description")}
                name="description"
                value={formData.description}
                onChange={handleChange}
                multiline
                rows={4}
              />
            </Grid>

            {/* Link */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label={t("post.link")}
                name="link"
                value={formData.link}
                onChange={handleChange}
                error={!!formErrors.link}
                helperText={formErrors.link}
              />
            </Grid>

            {/* Products - Replaced Select with Autocomplete */}
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                options={products}
                getOptionLabel={(option) => option.name}
                loading={isLoadingProducts}
                onChange={(event, newValue) => {
                  setFormData({
                    ...formData,
                    product: newValue ? newValue.id : null,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("post.product")}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingProducts ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            {/* File Upload */}
            <Grid item xs={12}>
              <Box
                sx={{
                  padding: 4,
                  maxWidth: 500,
                  margin: "auto",
                  width: "100%",
                  textAlign: "center",
                  boxShadow: 3,
                  borderRadius: 3,
                  backgroundColor: "background.paper",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  {t("post.uploadImage")}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginBottom: 2 }}
                >
                  {t("post.selectImageVideo")}
                </Typography>
                <Box sx={{ marginBottom: 2 }}>
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<CloudUpload />}
                    sx={{
                      backgroundColor: "primary.main",
                      "&:hover": { backgroundColor: "primary.dark" },
                    }}
                  >
                    {t("post.chooseFile")}
                    <Input
                      type="file"
                      onChange={handleFileChange}
                      inputProps={{ accept: "image/*,video/*" }}
                      sx={{ display: "none" }}
                    />
                  </Button>
                </Box>

                {loading && (
                  <Box sx={{ width: "100%", marginBottom: 2 }}>
                    <LinearProgress />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ marginTop: 1 }}
                    >
                      {t("post.uploading")}
                    </Typography>
                  </Box>
                )}

                {fileInfo && !loading && (
                  <Typography variant="subtitle1" color="text.primary">
                    <strong>File Name:</strong> {fileInfo.name}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "space-between", p: 2 }}>
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            sx={{ textTransform: "capitalize" }}
          >
            {t("post.cancel")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading || loadingUpload}
            startIcon={
              loading || loadingUpload ? (
                <CircularProgress size={20} color="inherit" />
              ) : null
            }
            sx={{ textTransform: "capitalize" }}
          >
            {t("post.save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddPost;

import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Input,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../context/AuthContext";
import { CloudUpload } from "@mui/icons-material";
import useAdd from "../../../api/useAdd";

const AddAd = ({ open, onClose }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;


  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  // Internal state (no validations)
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    external_link: "",
    image: null,
    video: null,
    start_date: getCurrentDateTime(),
    end_date: null,
  });

  const [formErrors, setFormErrors] = useState({
    title: "",
    external_link: "",
    start_date: null,
    end_date: null,
  });

  const [fileInfo, setFileInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);

      const fileType = file.type.startsWith("image")
        ? "Image"
        : file.type.startsWith("video")
        ? "Video"
        : "Unknown";

      setTimeout(() => {
        setFileInfo({
          file: file,
          type: fileType,
          name: file.name,
        });
        setLoading(false);
      }, 2000);
    }
  };

  const {
    handleAdd,
    resetForm,
    loading: loadingUpload,
  } = useAdd(
    "advertisement",
    token,
    "Advertisement added successfully",
    "Failed to add Advertisement"
  );

  // Update form fields when inputs change.
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const errors = {};

    // Title validation
    if (!formData.title.trim()) {
      errors.title = "Title is required.";
    }

    // External link validation
    if (
      formData.external_link &&
      !/^https?:\/\/.+$/.test(formData.external_link)
    ) {
      errors.external_link = "Invalid URL format.";
    }

    // Start and End date validation
    if (!formData.start_date) {
      errors.start_date = "Start date is required.";
    }
    if (!formData.end_date) {
      errors.end_date = "End date is required.";
    } else if (formData.start_date && formData.end_date < formData.start_date) {
      errors.end_date = "End date cannot be before the start date.";
    }

    setFormErrors(errors);

    // Return true if no errors
    return Object.keys(errors).length === 0;
  };

  // Submission handler: build a FormData payload and update the ad.
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form
    if (!validateForm()) {
      return; // Stop submission if there are validation errors
    }
    try {
      const updatedData =
        fileInfo?.type === "Video"
          ? { ...formData, video: fileInfo?.file }
          : fileInfo?.type === "Image"
          ? { ...formData, image: fileInfo?.file }
          : formData;

      const sendData = new FormData();

      Object.entries(updatedData).forEach(([key, value]) => {
        if (value !== null) {
          sendData.append(key, value);
          console.log(key, value);
        }
      });

      handleAdd(sendData);
      onClose()
    } catch (error) {
      alert("Failed to submit the form. Please try again.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <DialogTitle
          sx={{
            textAlign: "center",
            backgroundColor: "#3f51b5",
            color: "#fff",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">{t("ads.addAd")}</Typography>
        </DialogTitle>
        <DialogContent
          sx={{ padding: 2, maxHeight: "65vh", overflowY: "auto" }}
        >
          <Grid mt={1} container spacing={3}>
            {/* ad Name */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label={t("ad.title")}
                name="title"
                value={formData.title}
                onChange={handleChange}
                error={!!formErrors.title}
                helperText={formErrors.title}
              />
            </Grid>
            {/* Description */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label={t("ad.description")}
                name="description"
                value={formData.description}
                onChange={handleChange}
                multiline
                rows={4}
              />
            </Grid>
            {/* Link */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label={t("ad.link")}
                name="external_link"
                value={formData.external_link}
                onChange={handleChange}
                error={!!formErrors.external_link}
                helperText={formErrors.external_link}
              />
            </Grid>
            {/* Start date */}
            <Grid item xs={12} sm={6}>
              {/* <FormControlLabel>Start Date</FormControlLabel> */}
              <TextField
                fullWidth
                size="small"
                label="Start Date"
                InputLabelProps={{
                  shrink: true,
                }}
                name="start_date"
                type="datetime-local"
                value={formData.start_date}
                onChange={handleChange}
                error={!!formErrors.start_date}
                helperText={formErrors.start_date}
              />
            </Grid>
            {/* End date */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label="End Date"
                name="end_date"
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formData.end_date}
                onChange={handleChange}
                error={!!formErrors.end_date}
                helperText={formErrors.end_date}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  padding: 4,
                  maxWidth: 500,
                  margin: "auto",
                  width: "100%",
                  textAlign: "center",
                  boxShadow: 3,
                  borderRadius: 3,
                  backgroundColor: "background.paper",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  {t("ad.uploadImage")}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginBottom: 2 }}
                >
                  {t("ad.selectImageVideo")}
                </Typography>
                <Box sx={{ marginBottom: 2 }}>
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<CloudUpload />}
                    sx={{
                      backgroundColor: "primary.main",
                      "&:hover": { backgroundColor: "primary.dark" },
                    }}
                  >
                    {t("ad.chooseFile")}
                    <Input
                      type="file"
                      onChange={handleFileChange}
                      inputProps={{ accept: "image/*,video/*" }}
                      sx={{ display: "none" }}
                    />
                  </Button>
                </Box>

                {loading && (
                  <Box sx={{ width: "100%", marginBottom: 2 }}>
                    <LinearProgress />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ marginTop: 1 }}
                    >
                      {t("ad.updating")}
                    </Typography>
                  </Box>
                )}

                {fileInfo && !loading && (
                  <Typography variant="subtitle1" color="text.primary">
                    <strong>{t("ad.filename")}:</strong> {fileInfo.name}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", p: 2 }}>
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            sx={{ textTransform: "capitalize" }}
          >
            {t("manage_product.cancel")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            sx={{ textTransform: "capitalize" }}
          >
            {t("manage_product.save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddAd;
